import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, IconButton, Box, Typography, Popover, ListItem, List } from '@material-ui/core';

import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import moment from 'moment';
import ListItemText from '@material-ui/core/ListItemText';
import { useCollectionData, useCollectionOnce } from 'react-firebase-hooks/firestore';
import { firebaseStore, firebaseServerTimestamp } from 'utils/firebase';
import { apiPaths } from 'constants/ApiPaths';

import { FetchContext } from 'context/FetchContext';
import { AuthContext } from 'context/AuthContext';

import BlankModal from 'components/Common/BlankModal';
import NotificationForm from 'components/PagesNotificaitons/NotificationForm';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const notificationsRef = firebaseStore.collection('doctor_notification');

export default function HeaderDots() {
    const authContext = useContext(AuthContext);
    const currentUserId = authContext.authState.userInfo.id;

    const [anchorEl1, setAnchorEl1] = useState(null);

    const [newNotifQuery, setNewNotifQuery] = useState('');
    const [query, setQuery] = useState('');
    const _notificationsRef = useRef([]);
    const lastNotificationRef = useRef(null);

    const [notifications, setNotifications] = useState([]);

    const [_notifs, loadingMsg, errorMessage] = useCollectionOnce(query);
    const [_newNotif] = useCollectionData(newNotifQuery, { idField: 'id' });

    const unseenCount = notifications ? notifications.filter(noti => !noti.seen).length : 0;

    const _loadNotifRef = useRef(false);
    useEffect(() => {
        setQuery(
            notificationsRef
                .where('uid', '==', currentUserId)
                .orderBy('timestamp', 'desc')
                .limit(20)
        );
        setNewNotifQuery(
            notificationsRef
                .where('uid', '==', currentUserId)
                .orderBy('timestamp', 'desc')
                .limit(1)
        );
    }, []);

    useEffect(() => {
        if (_notifs && _notifs.docs && _notifs.docs.length > 0) {
            lastNotificationRef.current = _notifs.docs[_notifs.docs.length - 1];
            const _notis = _notifs.docs.map(doc => {
                return { id: doc.id, ...doc.data() };
            });
            if (_notificationsRef.current.length === 1) {
                // TODO: ene hardcoded shalgaj bgaa. Herew _newNotif turuulj ajillaad, hamgiin suuliin notification-g awchirsan bol override hiine. Busad tohioldold (paging, _newNotif suuld duudagdah) omnoh notif-uud deer zalgana
                _notificationsRef.current = [..._notis];
            } else {
                _notificationsRef.current = [..._notificationsRef.current, ..._notis];
            }
            setNotifications([..._notificationsRef.current]);
        } else {
        }
        _loadNotifRef.current = false;
    }, [_notifs]);

    useEffect(() => {
        if (_newNotif && (_newNotif || []).length > 0) {
            if (_notificationsRef.current.find(el => el.id == _newNotif[0].id)) {
            } else {
                _notificationsRef.current = [..._newNotif, ..._notificationsRef.current];
                setNotifications([..._notificationsRef.current]);
            }
        }
    }, [_newNotif]);

    const handleClick1 = event => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    const open1 = Boolean(anchorEl1);

    const makeSeen = async noti => {
        const _index = _notificationsRef.current.findIndex(el => el.id === noti.id);
        if (!_notificationsRef.current[_index].seen) {
            _notificationsRef.current[_index] = { ..._notificationsRef.current[_index], seen: true };
            setNotifications([..._notificationsRef.current]);

            const notiRef = notificationsRef.doc(noti.id);
            await notiRef.update({
                seen: true,
                seenTimestamp: firebaseServerTimestamp
            });
        }
    };

    const seenStyle = {
        backgroundColor: 'var(--gray)'
    };
    const unseenStyle = {
        backgroundColor: 'var(--primary)'
    };

    const handleMedegdelScrollEnd = () => {
        if (anchorEl1 && !_loadNotifRef.current) {
            _loadNotifRef.current = true;
            setQuery(
                notificationsRef
                    .where('uid', '==', currentUserId)
                    .orderBy('timestamp', 'desc')
                    .startAfter(lastNotificationRef.current)
                    .limit(10)
            );
        }
    };

    return (
        <Fragment>
            <div className="d-flex align-items-center popover-header-wrapper">
                <Hidden smDown>
                    <Box component="span" pr="2">
                        <IconButton onClick={handleClick1} color="inherit" className="btn-inverse mx-1 d-50">
                            {unseenCount > 0 && (
                                <div className="badge badge-pill badge-warning badge-header">{unseenCount}</div>
                            )}
                            <NotificationsActiveTwoToneIcon />
                        </IconButton>
                        <Popover
                            open={open1}
                            anchorEl={anchorEl1}
                            onClose={handleClose1}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            classes={{
                                paper: 'app-header-dots'
                            }}>
                            <div className="popover-custom-xl overflow-hidden p-2">
                                <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                                    <div className="bg-composed-img-3 bg-composed-wrapper--image"></div>
                                    <div className="bg-composed-wrapper--content text-light p-2">
                                        <h4 className="font-size-xl font-weight-bold mb-2">Мэдэгдэл</h4>
                                    </div>
                                </div>
                                <div className="height-280">
                                    {/* <div> */}
                                    <PerfectScrollbar onYReachEnd={handleMedegdelScrollEnd}>
                                        <div className="table-responsive p-2">
                                            <List>
                                                {/* {_notificationsRef.current.map((row, index) => ( */}
                                                {notifications.map((row, index) => (
                                                    <ListItem
                                                        button
                                                        className="rounded-sm opacity-8 my-1"
                                                        style={row.seen ? seenStyle : unseenStyle}
                                                        key={index}
                                                        onClick={() => makeSeen(row)}>
                                                        <ListItemText
                                                            className="opacity-10"
                                                            primary={replaceUTCtoLocalTime(row.body)}
                                                            style={{
                                                                color: row.seen ? 'var(--second)' : 'var(--white)',
                                                                textAlign: 'justify'
                                                            }}
                                                            secondary={
                                                                <span
                                                                    className="text-black"
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end',
                                                                        fontSize: '12px'
                                                                    }}>
                                                                    {moment(row.timestamp.seconds * 1000).format(
                                                                        'YYYY-MM-DD HH:mm'
                                                                    )}
                                                                </span>
                                                            }
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </Popover>
                    </Box>
                </Hidden>
            </div>
        </Fragment>
    );
}

// msg: include dateString, YYYY-MM-DD HH:mm UTC
// return: msg with LOCAL TIME dateString
export const replaceUTCtoLocalTime = msg => {
    const regEx = new RegExp('\\d{4}-\\d{2}-\\d{2} \\d{2}:\\d{2}', 'g');
    let _msg = msg;
    let _matches;
    while ((_matches = regEx.exec(msg)) !== null) {
        let _replaceStr = _matches[0];
        _replaceStr = `${_replaceStr.replace(' ', 'T')}:00Z`;
        _replaceStr = moment(_replaceStr).format('YYYY-MM-DD HH:mm');
        _msg = _msg.replace(_matches[0], _replaceStr);
    }
    return _msg;
};
