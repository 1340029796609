import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { IconButton, Box, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { emchLogo, emchLogoText, isTelemedApp } from 'constants/CommonConstants';
import { setSidebarToggleMobile } from 'reducers/ThemeOptions';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { pagePaths } from 'constants/PagePaths';

const SidebarHeader = props => {
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const { sidebarToggleMobile, setSidebarToggleMobile, setSidebarToggle, sidebarToggle, sidebarHover } = props;

  const renderLogo = () => {
    if (isTelemedApp) {
      return (
        <Fragment>
          {sidebarToggle ? (
            <img className="app-header-logo-img" alt="Telemed" src={emchLogo} />
          ) : (
            <img className="app-header-logo-img" alt="Telemed" src={emchLogoText} />
          )}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <img className="app-header-logo-img" alt="Telemed" src={emchLogo} />
        {!sidebarToggle && <img className="app-header-logo-img" alt="Telemed" src={emchLogoText} />}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div
        className={clsx('app-sidebar-header', {
          'app-sidebar-header-close': sidebarToggle && !sidebarHover
        })}>
        <Box className="header-logo-wrapper" title="Telemed">
          <Link to={pagePaths.dashboard} className="header-logo-wrapper-link">
            <IconButton color="primary" size="medium" className="header-logo-wrapper-btn">
              <div style={{ display: 'flex' }}>
                  { renderLogo() }
              </div>
            </IconButton>
          </Link>
        </Box>
        <Box
          className={clsx('app-sidebar-header-btn', {
            'app-sidebar-header-btn-close': sidebarToggle && !sidebarHover
          })}>
          <Tooltip title={sidebarToggle ? 'Дэлгэх' : 'Хураах'} placement="right">
            <IconButton color="inherit" onClick={toggleSidebar} size="medium">
              {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="app-sidebar-header-btn-mobile">
          <Tooltip title={sidebarToggle ? 'Дэлгэх' : 'Хураах'} placement="right">
            <IconButton color="inherit" onClick={toggleSidebarMobile} size="medium">
              {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </Fragment>
  );
};
const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
