import env from 'env-var'

export const isTelemedApp = env.get('REACT_APP_PAGE_TITLE').default('Emch app').asString() === 'Doctor Telemedapp'
let tempEmchLogo
let tempEmchLogoText
let tempProjectLogo

if (isTelemedApp) {
  tempEmchLogo = require('assets/images/emch_logo.png')
  tempEmchLogoText = require('assets/images/telemedapp-logo.png');
  tempProjectLogo = require('assets/images/telemedapp-logo.png');
} else {
  tempEmchLogo = require('assets/images/emch_logo.png')
  tempEmchLogoText = require('assets/images/emch_logo_text.png'); 
  tempProjectLogo = require('assets/images/emch_logo_text.png'); 
}

export const emchLogo = tempEmchLogo
export const emchLogoText = tempEmchLogoText
export const projectLogo = tempProjectLogo

export const INTENTTYPE_IMAGE = 'jpg';
export const INTENTTYPE_TEXT = 'text';

export const DOCTOR_CONFIG_SERVICE = 'Үйлчилгээ';
export const DOCTOR_CONFIG_VIA = 'Үйлчилгээний хэрэгсэл';
export const DOCTOR_CONFIG_SCHEDULE = 'Ажлын цагийн хуваарь';
export const DOCTOR_SERVICE_EMERGENCY = 'Онцгой үеийн тусламж';
export const DOCTOR_SERVICE_FAMILY = 'Өрхийн эмч';
export const DOCTOR_SERVICE_ONLINE = 'Онлайн зөвлөх эмч';
export const DOCTOR_SERVICE_VIDEO = 'Видео дуулага хийх';
export const DOCTOR_SERVICE_VOICE = 'Войсе дуулага хийх';
export const DOCTOR_SERVICE_TEXT = 'Мессежээр харилцах';
export const DOCTOR_SERVICE_SETTING = 'Үзлэгийн тохиргоо';

export const PAGESIZEOPTIONS = [5, 10, 20, 50];
