import env from 'env-var'

// export const API_BASE_URL = process.env.NODE_ENV === 'production' ? 'https://emch.app/api':  'http://localhost/api';
export const API_BASE_URL = process.env.NODE_ENV === 'production' ? env.get('REACT_APP_BASE_URL').default('https://emch.app/api').asString() :  'http://localhost:8055/api';
// export const IMAGE_SERVER_URL = process.env.NODE_ENV === 'production' ? 'https://emch.app': 'http://localhost';
export const IMAGE_SERVER_URL = process.env.NODE_ENV === 'production' ? env.get('REACT_APP_IMAGE_SERVER_URL').default('https://emch.app').asString() :  'http://localhost:8055/api';

export const API_STATUS_SUCCESS = 'success';
export const API_STATUS_INVALID_INPUT = 'invalidInput';
export const API_STATUS_ALREADY_EXISTS = 'failed';
