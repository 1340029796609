import React, { Fragment, useContext } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Avatar, Box, Typography, Menu, Button, List, ListItem } from '@material-ui/core';

import { connect } from 'react-redux';

import avatar0 from 'assets/images/avatars/avatar0.png';
import { AuthContext } from 'context/AuthContext';
import { pagePaths } from 'constants/PagePaths';

const SidebarUserbox = props => {
  const authContext = useContext(AuthContext);
  const userInfo = authContext.authState.userInfo;

  const { sidebarToggle, sidebarHover } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <Fragment>
      <Box
        className={clsx('app-sidebar-userbox', {
          'app-sidebar-userbox--collapsed': sidebarToggle && !sidebarHover
        })}>
        <Menu
          id="userMenu"
          anchorEl={anchorEl}
          keepMounted
          getContentAnchorEl={null}
          classes={{ list: 'p-0' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <div className="dropdown-menu-right dropdown-menu-xl p-0">
            <div className="bg-composed-wrapper bg-vicious-stance mt-0">
              <div className="bg-composed-wrapper--image bg-composed-img-5" />
              <div className="bg-composed-wrapper--content text-light text-center p-4">
                <h5 className="mb-1">Scrollable</h5>
                <p className="mb-0 opacity-7">This menu box is scrollable (sm)</p>
              </div>
            </div>
            <div className="scroll-area-sm shadow-overflow">
              <PerfectScrollbar>
                <List className="flex-column">
                  <ListItem button>
                    <div className="nav-link-icon opacity-6">
                      <FontAwesomeIcon icon={['far', 'chart-bar']} />
                    </div>
                    <span>Services</span>
                  </ListItem>
                  <ListItem button>
                    <div className="nav-link-icon opacity-6">
                      <FontAwesomeIcon icon={['far', 'question-circle']} />
                    </div>
                    <span>Layouts</span>
                    <span className="ml-auto badge badge-warning">512</span>
                  </ListItem>
                  <ListItem button>
                    <div className="nav-link-icon opacity-6">
                      <FontAwesomeIcon icon={['far', 'user-circle']} />
                    </div>
                    <span>Reports</span>
                  </ListItem>
                  <Typography className="text-black py-2 px-3" component="div">
                    Others
                  </Typography>
                  <ListItem button>
                    <div className="nav-link-icon opacity-6">
                      <FontAwesomeIcon icon={['far', 'object-group']} />
                    </div>
                    <span>Components</span>
                  </ListItem>
                  <ListItem button>
                    <div className="nav-link-icon opacity-6">
                      <FontAwesomeIcon icon={['far', 'chart-bar']} />
                    </div>
                    <span>Services</span>
                  </ListItem>
                </List>
              </PerfectScrollbar>
            </div>
            <div className="card-footer d-flex justify-content-between">
              <Button size="small" variant="outlined" color="secondary">
                Action
              </Button>
              <Button color="primary" variant="contained" size="small">
                <span>View details</span>
              </Button>
            </div>
          </div>
        </Menu>
        <Avatar alt={userInfo.firstname} src={userInfo.avatar ? userInfo.avatar : avatar0} />
        <Box className="app-sidebar-userbox-name">
          <Box>
            <b>
              {userInfo.firstname && userInfo.firstname} {userInfo.lastname && userInfo.lastname}
            </b>
          </Box>
          <Box className="app-sidebar-userbox-description">{userInfo.email && userInfo.email}</Box>
          <Box className="app-sidebar-userbox-btn-profile">
            <Button size="small" color="secondary" variant="contained" className="text-white" href={pagePaths.profile}>
              Эмчийн профайл
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(SidebarUserbox);
