import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { translatedMessages } from 'i18n';
import env from 'env-var';

const LangContext = React.createContext('');
const isTelemedApp =
  env
    .get('REACT_APP_PAGE_TITLE')
    .default('Emch app')
    .asString() === 'Doctor Telemedapp';

const DEFAULT_LOCALE = isTelemedApp ? 'tr' : 'mn';

const defaultLocale = localStorage['locale'] ? localStorage['locale'] : DEFAULT_LOCALE;

export function LanguageProvider(props) {
  const [currentLocale, setCurrentLocale] = useState(defaultLocale);

  const onChangeLanguage = e => {
    const selectedLocale = e;
    setCurrentLocale(selectedLocale);
    localStorage.setItem('locale', selectedLocale);
  };

  return (
    <IntlProvider locale={currentLocale} messages={translatedMessages[currentLocale]}>
      <LangContext.Provider value={{ currentLocale, isTelemedApp, setCurrentLocale, onChangeLanguage }}>{props.children}</LangContext.Provider>
    </IntlProvider>
  );
}
export default LangContext;
