import React, { Fragment } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';

const ListItem = props => {
  const { title, icon: Icon, link } = props;

  return (
    <Fragment>
      {title && (
        <Tooltip arrow placement="right" title={title}>
          <Button className="app-sidebar-btn-wrapper" activeClassName="active" component={RouterLink} to={link}>
            {Icon && <Icon className="app-sidebar-icon" />}
          </Button>
        </Tooltip>
      )}
    </Fragment>
  );
};
export default ListItem;
