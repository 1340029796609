import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {},
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const CustomButton = ({ type, children, size, loading, onClick, color, fullWidth, disabled, startIcon }) => {
  const classes = useStyles();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: true
  });

  return (
    <div className={classes.wrapper}>
      <Button
        variant="contained"
        fullWidth={fullWidth}
        color={color ? color : 'primary'}
        disabled={loading || disabled}
        type={type}
        className={buttonClassname}
        size={size}
        startIcon={startIcon}
        onClick={onClick}>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          {children}
      </Button>
      
    </div>
  );
};

export default CustomButton;
