import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, IconButton, AppBar, Paper, Box } from '@material-ui/core';

import { connect } from 'react-redux';
import { setSidebarToggleMobile } from 'reducers/ThemeOptions';

import SidebarHeader from '../SidebarHeader';
import SidebarUserbox from '../SidebarUserbox';
import SidebarMenu from '../SidebarMenu';

import navItems from '../Sidebar/navItems';

// import projectLogo from 'assets/images/logo_square.png';
import { projectLogo }  from 'constants/CommonConstants';
import MenuIcon from 'layout-components/SidebarCollapsed/MenuIcon';
import { pagePaths } from 'constants/PagePaths';

const SidebarCollapsed = props => {
    const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

    const sidebarMenuContent = (
        <div>
            {navItems.map(list => (
                <SidebarMenu component="div" key={list.label} pages={list.content} title={list.label} />
            ))}
        </div>
    );

    const { setSidebarToggleMobile, sidebarToggleMobile } = props;

    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    open={sidebarToggleMobile}
                    onClose={closeDrawer}
                    variant="temporary"
                    elevation={4}
                    className="app-sidebar-wrapper-lg">
                    <SidebarHeader />
                    <PerfectScrollbar>
                        <SidebarUserbox />
                        {sidebarMenuContent}
                    </PerfectScrollbar>
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Paper elevation={7} square className="app-sidebar-collapsed-wrapper">
                    <AppBar color="secondary" position="relative" elevation={0}>
                        <div className="sidebar-collapsed-logo">
                            <Box className="header-logo-wrapper" title="Telemed">
                                <Link to={pagePaths.dashboard} className="header-logo-wrapper-link">
                                    <IconButton color="primary" size="medium" className="header-logo-wrapper-btn">
                                        <img className="app-sidebar-logo" alt="Telemed" src={projectLogo} />
                                    </IconButton>
                                </Link>
                            </Box>
                        </div>
                    </AppBar>
                    <div className="app-sidebar--content">
                        <PerfectScrollbar>
                            {navItems.map((list, index) => (
                                <div key={index}>
                                    <MenuIcon menu={list.content} />
                                </div>
                            ))}
                        </PerfectScrollbar>
                    </div>
                </Paper>
            </Hidden>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    sidebarShadow: state.ThemeOptions.sidebarShadow,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarCollapsed);
