import React from 'react';
import TextField from '@material-ui/core/TextField';

const InputField = ({ name, type, placeholder, autoComplete, variant, margin, label, field, rows, multiline,disabled }) => (  
  <TextField
    fullWidth
    name={name}
    type={type}
    variant={variant}
    margin={margin}
    label={label}
    placeholder={placeholder}
    autoComplete={autoComplete}
    multiline={multiline}
    rows={rows}
    disabled={disabled}
    {...field}
  />
);

export default InputField;
