const apiPaths = {
    register: '/doctor/signup',
    login: '/doctor/login',
    recover: '/doctor/recover',
    changePassword: '/doctor/changePassword',
    userInfo: '/doctor/userInfo',

    doctorProfile: '/doctor/profile',
    doctorCategoryList: '/doctor/category/list',

    doctorSetting: '/doctor/setting',
    workHour: '/doctor/workHour',
    timeOff: '/doctor/timeOff',
    updateDoctorTimezone: '/doctor/timezone',

    service: '/system/service',

    getDoctorStat: '/doctor/stat',

    getDoctorRating: '/doctor/rating',

    //customer related
    getCustomerList: '/doctor/customer',
    getCustomerListNiit: '/doctor/stat/customerListNiitUzsen',
    getCustomerListEmchilgeeZuvluguu: '/doctor/stat/customerListEmchilgeeBichsen',
    getCustomerListNegUdaa: '/doctor/stat/customerListNegUdaaUzsen',
    getCustomerListDavtanUzeh: '/doctor/stat/customerListDavtanUzehShaardlagatai',
    getCustomerListDavtanUzsen: '/doctor/stat/customerListDavtanUzsen',
    getCustomerListBaingiin: '/doctor/stat/customerListBaingaHyanahShaardlagatai',
    getCustomerDetail: '/doctor/customer',
    getAddressByZipcode: 'doctor/customer/address/byZipcode',

    healthRecord: '/doctor/order/healthRecord',
    getbaingaHyanahEseh: customerId => `/doctor/customer/${customerId}/baingaHyanahEseh`,

    doctorList: '/doctor/users/page/',
    customerList: '/customer/users/page/',

    doctorNotificationList: '/doctor/notification',

    getAppointmentList: '/doctor/appointment',
    getAppointmentDetail: appointmentId => `/doctor/appointment/${appointmentId}`,

    checkAppointmentWithHealthRecord: customerId => `/doctor/appointment/${customerId}/checkWithHealthRecord`,

    setBaingaHyanah: `/doctor/customer/healthrecord/updateBaingaHyanahEseh`,
    setDavtanUzeh: `/doctor/customer/healthrecord/updateDavtanUzehEseh`,

    //live call related
    getDoctorLiveCall: '/doctor/call',

    doctorCallAccept: '/doctor/call/accept',
    doctorCallCancel: '/doctor/call/cancel',
    doctorCallFinish: '/doctor/call/finish',

    //chat related
    doctorChat: '/doctor/chat',
    refreshCustomerList: '/doctor/chat/refreshCustomerList',
    refreshCustomer: '/doctor/chat/init',
    getChatCustomerList: '/doctor/chat/customer',
    getChatCustomer: (customerId) => `/doctor/chat/customer/${customerId}`,
    notifyNewMessage: '/doctor/chat/notifyNewMessage',

    //content related
    getTerm: 'system/pageContent/term',

    // post related
    createPost: '/doctor/post/create',
    updatePost: postId => `/doctor/post/${postId}/update`,
    getPostList: '/doctor/post/list',
    getOnePost: postId => `/doctor/post/${postId}/detail`,
    deleteOnePost: postId => `/doctor/post/${postId}/delete`,
    imageUpload: '/doctor/post/image',
    postTagList: '/doctor/post/tag/list',
    publishPost: postId => `/doctor/post/${postId}/publish`,
    unPublishPost: postId => `/doctor/post/${postId}/unpublish`,

    // comment related
    getCommentList: `/comment`,
    createComment: '/comment',
    getSubCommentList: commentId => `/comment/${commentId}/subComment`,

    listIcd10: '/doctor/icd10/list',
    customerUzlegStatus: customerId => {
        return `/doctor/customer/${customerId}/customerGeneralStatus`;
    },

    CustomerDavtanStatus: '/doctor/customer/healthrecord/updateDavtanUzehEseh',
    CustomerHyanaltStatus: '/doctor/customer/healthrecord/updateBaingaHyanahEseh',

    // image upload for doctor post
    imageUploader: '/imageapi/upload',

    // Apppointment related
    cancelAppointment: `/doctor/appointment/cancel`,

    serviceDurationList: '/doctor/appointment/service/durationList',
    createDoctorService: '/doctor/service/create',
    updateDoctorService: id => `/doctor/service/${id}/update`,
    getDoctorService: id => `/doctor/service/${id}/detail`,
    deleteDoctorService: id => `/doctor/service/${id}/delete`,
    getDoctorServiceList: '/doctor/service/list',
    getServiceList: '/doctor/servicetype/list',
    getAvailableTimeslotList: ({ doctorServiceId, customerId }) => {
        return `/doctor/appointment/${doctorServiceId}/getAvailableTimeslotList?customerId=${customerId}`;
    },
    postponeAppointment: doctorAppointmentId => {
        return `/doctor/appointment/${doctorAppointmentId}/postpone`;
    },
    getDoctorList: '/doctor/doctor/list',

    // chat mention related
    mentionDoctorList: '/doctor/mention/list',

    // new video call (agora) related
    getAgoraToken: '/doctor/getAgoraToken'
};

export { apiPaths };
