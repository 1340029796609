import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import LangContext from 'context/LangContext';

import MongolFlag from 'assets/images/mongolian-flag-32.png';
import EnglishFlag from 'assets/images/england-flag-32.png';
import TurkishFlag from 'assets/images/turkish-flag-32.png';

const SwitchLanguage = () => {
  const myLangContext = useContext(LangContext);
  const {currentLocale, isTelemedApp, onChangeLanguage} = myLangContext
  const [flag, setFlag] = useState(MongolFlag);  

  useEffect(() => {
    if (currentLocale === 'mn') {
      setFlag(MongolFlag)
    } else if (currentLocale === 'en') {
      setFlag(EnglishFlag)
    } else if (currentLocale === 'tr') {
      setFlag(TurkishFlag)
    }
  }, [currentLocale])

  const onClick = () => {
    if (isTelemedApp) {
      if (currentLocale === 'tr') {
        onChangeLanguage('en');
      } else {
        onChangeLanguage('tr');
      }
    } else {
      if (currentLocale === 'mn') {
        onChangeLanguage('en');
      } else {
        onChangeLanguage('mn');
      }
    }
  };

  return (
    <Button
      onClick={onClick}
      style={{
        margin: '5px',
        borderRadius: '25px',
        minWidth: '50px',
        minHeight: '50px',
        padding: '0px'
      }}>
      <img src={flag} alt="flag mongolia" style={{ width: '32px', height: '32px', borderRadius: '16px', objectFit: 'unset' }} />
    </Button>
  );
};

export default SwitchLanguage;
