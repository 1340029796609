import React, { createContext, useState, useEffect, useRef, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { firebaseStore, firebaseServerTimestamp } from 'utils/firebase';
import { AuthContext } from 'context/AuthContext';
import { FetchContext } from 'context/FetchContext';
import { apiPaths } from 'constants/ApiPaths';

const CallContext = createContext();
const { Provider } = CallContext;

let RING_AUDIO = null

const CallProvider = ({ children }) => {
    const history = useHistory()
    const authContext = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);    
    const currentUserId = authContext.authState.userInfo.id;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [callList, setCallList] = useState([]);
    const callUnsubscribeRef = useRef(null);

    const liveCallQuery = firebaseStore
        .collection('live_video_meeting')
        .where('doctorId', '==', currentUserId)
        .where('status', '==', 'CALLING')
        .orderBy('timestamp', 'desc')
        .limit(12);

    useEffect(() => {
        fetchAndListenCallList();
    }, [currentUserId]);

    const fetchAndListenCallList = async () => {
        if (callUnsubscribeRef.current) {
            // unsubscribe any previous firebase listener
            callUnsubscribeRef.current();
        }

        if (currentUserId) {
            const unsubscribe = liveCallQuery.onSnapshot(function(snapshot) {
                let added = [];
                let modified = [];
                let removed = [];

                snapshot.docChanges().forEach(function(change) {
                    let liveCall = change.doc.data();
                    liveCall = { ...liveCall, id: change.doc.id };

                    console.log('incoming liveCall change type:', change.type, ' meeting:', liveCall);

                    if (change.type === 'added') {
                        added.push(liveCall);
                        playRingSound()
                        // addNoti(liveCall);
                    } else if (change.type === 'modified') {
                        modified.push(liveCall);
                    } else if (change.type === 'removed') {
                        removed.push(liveCall);

                        // removeNoti(liveCall);
                    }
                });

                setCallList(prev => {
                    //update live call llist
                    let temp = [...prev, ...added];

                    temp = temp.map(item => {
                        const foundedItem = modified.find(inner => inner.id === item.id);

                        if (foundedItem) {
                            return foundedItem;
                        }
                        return item;
                    });

                    temp = temp.filter(item => !removed.some(inner => inner.id === item.id));
                    if (!temp || temp.length < 1) {
                        pauseRingSound()
                    }

                    return temp;
                });
            });

            callUnsubscribeRef.current = unsubscribe;
        } else {
            callUnsubscribeRef.current = null;
        }

        setCallList([]);
    };

    const addNoti = liveCall => {
        playRingSound()

        const message = `${liveCall.customerName} -с дуудлага ирж байна!`;
        const action = key => (
            <>
                <button onClick={() => acceptCall(key)}>Авах</button>
                <button onClick={() => cancelCall(key)}>Таслах</button>
            </>
        );

        enqueueSnackbar(message, {
            key: liveCall.liveCallId,
            variant: 'success',
            persist: true,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            action
        });
    };

    const removeNoti = liveCall => {
        //remove noti if exists

        pauseRingSound()

        closeSnackbar(liveCall.liveCallId);
    };

    const acceptCall = async liveCallId => {
        pauseRingSound()

        //1. call api acceptCall
        const params = {
            liveCallId: liveCallId
        }
        await fetchContext.authAxios.post(apiPaths.doctorCallAccept, params);

        //2. close noti
        closeSnackbar(liveCallId);

        //3. go to video conference room
        history.push('/videoRoom?liveCallId=' + liveCallId)
    };

    const cancelCall = async liveCallId => {
        pauseRingSound()

        //1. call api cancelCall
        const params = {
            liveCallId: liveCallId
        }
        await fetchContext.authAxios.post(apiPaths.doctorCallCancel, params);

        //2. close noti
        closeSnackbar(liveCallId);
    };

    const finishCall = async liveCallId => {
        //1. call api cancelCall
        const params = {
            liveCallId: liveCallId
        }
        await fetchContext.authAxios.post(apiPaths.doctorCallFinish, params);
    };

    const playRingSound = () => {
        console.log('calling...')
        if (!RING_AUDIO) {
            RING_AUDIO = new Audio('/sound/swinging-617.mp3');
            RING_AUDIO.loop = true
        }
        RING_AUDIO.play()
    }

    const pauseRingSound = () => {
        console.log('paused...')
        if (RING_AUDIO) {
            RING_AUDIO.pause()
        }
    }

    return (
        <Provider
            value={{
                callList,
                acceptCall,
                cancelCall,
                finishCall
            }}>
            {children}
        </Provider>
    );
};

export { CallContext, CallProvider };
