import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const BlankModal = props => {
    return (
        <Dialog
            scroll="body"
            maxWidth={props.maxWidth}
            fullWidth={props.fullWidth}
            open={props.open}
            onClose={props.onClose}>
            <DialogTitle onClose={props.onClose}>
                <div className="font-size-lg">{props.title}</div>
                <IconButton
                    aria-label="close"
                    style={{ position: 'absolute', right: 5, top: 5 }}
                    onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>{props.children}</DialogContent>
        </Dialog>
    );
};

export default BlankModal;
