import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/firebase-messaging";
import "firebase/firebase-database";
import "firebase/storage"
import env from 'env-var'

// const config = {
//     apiKey: "AIzaSyAGMjcLqY9ZBFzbVgCfhPJyL21plaCKMkI",
//     authDomain: "telemedchat-87995.firebaseapp.com",
//     databaseURL: "https://telemedchat-87995.firebaseio.com",
//     projectId: "telemedchat-87995",
//     storageBucket: "telemedchat-87995.appspot.com",
//     messagingSenderId: "509452311517",
//     appId: "1:509452311517:web:14fac2815f4a2145093434",
//     measurementId: "G-ZYP9923X2W"
// }
const config = env.get('REACT_APP_PAGE_TITLE').default('Emch app').asString() === 'Doctor Telemedapp'  ? {
    apiKey: "AIzaSyAfE6cNXjKr_uwbHAeLG-JAmRtnmlAyDok",
    authDomain: "telemedapp-nsp.firebaseapp.com",
    databaseURL: "https://telemedapp-nsp-default-rtdb.firebaseio.com",
    projectId: "telemedapp-nsp",
    storageBucket: "telemedapp-nsp.appspot.com",
    messagingSenderId: "111605102",
    appId: "1:111605102:web:721baa49f37fca7061fd7f",
    measurementId: "G-C5NJ3V3QF2"
  } : {
    apiKey: 'AIzaSyAGMjcLqY9ZBFzbVgCfhPJyL21plaCKMkI',
    authDomain: 'telemedchat-87995.firebaseapp.com',
    databaseURL: 'https://telemedchat-87995.firebaseio.com',
    projectId: 'telemedchat-87995',
    storageBucket: 'telemedchat-87995.appspot.com',
    messagingSenderId: '509452311517',
    appId: '1:509452311517:web:14fac2815f4a2145093434',
    measurementId: 'G-ZYP9923X2W'
  };

firebase.initializeApp(config)


export const firebaseAuth = firebase.auth()
export const firebaseStore = firebase.firestore()
export const firebaseServerTimestamp = firebase.firestore.FieldValue.serverTimestamp()

export const firebaseMessaging = firebase.messaging.isSupported() ?  firebase.messaging() : null
export const firebaseDatabase = firebase.database()
export const firebaseDBServerTimestamp = firebase.database.ServerValue.TIMESTAMP
export const firebaseStorage = firebase.storage()