import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Card, Button, Tooltip } from '@material-ui/core';
import { Form, Formik } from 'formik';
import FormInput from 'components/Common/FormInput';
import AlertLabel from 'components/Common/AlertLabel';
import * as Yup from 'yup';
import { publicFetch } from 'utils/PublicFetch';
import { apiPaths } from 'constants/ApiPaths';
import LoginBg from '../../../assets/images/register.png';
import { FormattedMessage } from 'react-intl';
import messages from '../../../appMessages';
import SwitchLanguage from 'components/SwitchLanguage';


const SendOtpSchema = Yup.object().shape({
    email: Yup.string().required('Имэйл оруулна уу')
    // validationCode: Yup.string().required('Баталгаажуулах код оруулна уу'),
    // newPassword: Yup.string().required('Шинэ нууц үгээ оруулна уу'),
});

const RecoverSchema = Yup.object().shape({
    otpCode: Yup.string().required('Нууц код оруулна уу'),
    password: Yup.string()
        .min(6, '6-аас дээш оронтой нууц үг оруулна уу')
        .required('Нууц үгээ оруулна уу'),
    passwordConfirm: Yup.string().when('password', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], 'Нууц үгээ давтан оруулна уу')
    })
});

const useStyles = makeStyles(theme => ({
    myButton: {
        margin: theme.spacing(1),
        color: '#079BAB',
        borderColor: '#079BAB'
    }
}));

export default function LivePreviewExample() {
    const history = useHistory();
    const classes = useStyles();
    const [recoverSuccess, setRecoverSuccess] = useState();
    const [recoverError, setRecoverError] = useState();
    const [loading, setLoading] = useState(false);
    const [doctorEmail, setDoctorEmail] = useState(null);
    const [recoverOtpId, setRecoverOtpId] = useState(null);
    const [doctorId, setDoctorId] = useState(null);

    const submitSendOtpForm = async formData => {
        try {
            console.log('bababab');
            setLoading(true);
            setRecoverSuccess(null);
            setRecoverError(null);

            setDoctorEmail(formData.email);

            const { data } = await publicFetch.post(apiPaths.recover + '/sendOtp', formData);
            setRecoverOtpId(data.result.recoverOtpId);

            setLoading(false);
        } catch (error) {
            // console.log('login error:', error);
            setLoading(false);
            const { data } = error.response;
            setRecoverError(error && data ? data.message : 'Error');
            setRecoverSuccess(null);
        }
    };

    const submitRecoverForm = async formData => {
        try {
            setLoading(true);
            setRecoverSuccess(null);
            setRecoverError(null);

            const params = {
                ...formData,
                recoverOtpId,
                email: doctorEmail
            };

            //console.log('params:', params)

            const { data } = await publicFetch.post(apiPaths.recover + '/verifyOtpAndRecover', params);
            setDoctorId(data.result.doctorId);

            setRecoverSuccess('Нууц үг амжилттай солигдлоо. Та шинэ нууц үгээ ашиглан нэвтрэх боломжтой.');
            setLoading(false);
        } catch (error) {
            console.log('login error:', error);
            setLoading(false);
            const { data } = error.response;
            setRecoverError(error && data ? data.message : 'Error');
            setRecoverSuccess(null);
        }
    };

    return (
        <Fragment>
            <div className="app-wrapper bg-white">
                <div className="app-main d-flex">
                    <div className="app-content p-0 flex-fill ">
                        <div className="d-flex flex-row-reverse">
                            <SwitchLanguage />
                        </div>
                        <div className="app-inner-content-layout--main">
                            <div className="flex-grow-1 w-100 d-flex align-items-center">
                                <div className="bg-composed-wrapper--content">
                                    <div className="hero-wrapper bg-composed-wrapper  min-vh-100">
                                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                                            <div
                                                className="bg-composed-wrapper--image"
                                                // style={{ backgroundImage: 'url(' + hero3 + ')' }}
                                            />
                                            <div className="bg-composed-wrapper--bg " />
                                            <div className="bg-composed-wrapper--content text-center py-5">
                                                <Grid
                                                    item
                                                    xl={5}
                                                    lg={6}
                                                    md={10}
                                                    sm={12}
                                                    className="mx-auto text-center text-white">
                                                    <h1
                                                        className="display-3 mb-3 font-weight-bold"
                                                        style={{ color: '#079BAB', textAlign: 'center' }}>
                                                        <FormattedMessage {...messages.recoverPassword} />
                                                    </h1>
                                                    {/* <h3 className="font-size-lg line-height-sm font-weight-light d-block px-3 mb-5 text-white-50">
                            Та өөрийн бүртэлтэй нэвтрэх имэйл хаяг ашиглан нууц үгээ сэргээх боломжтой.
                          </h3> */}
                                                    <Card className="p-5 mx-5 text-center">
                                                        {!recoverOtpId ? (
                                                            <Formik
                                                                key={'sendOtpForm'}
                                                                enableReinitialize={true}
                                                                initialValues={{
                                                                    email: ''
                                                                }}
                                                                onSubmit={values => submitSendOtpForm(values)}
                                                                validationSchema={SendOtpSchema}>
                                                                {() => (
                                                                    <Form className="mt-8">
                                                                        {recoverSuccess && (
                                                                            <AlertLabel
                                                                                text={recoverSuccess}
                                                                                color="success"
                                                                                icon={['far', 'object-group']}
                                                                            />
                                                                        )}
                                                                        {recoverError && (
                                                                            <AlertLabel
                                                                                text={recoverError}
                                                                                color="warning"
                                                                                icon={['far', 'question-circle']}
                                                                            />
                                                                        )}

                                                                        <div>
                                                                            <div className="mb-3">
                                                                                <FormInput
                                                                                    variant="outlined"
                                                                                    label={
                                                                                        <FormattedMessage
                                                                                            {...messages.insertRegisteredEmail}
                                                                                        />
                                                                                    }
                                                                                    name="email"
                                                                                    type="text"
                                                                                    placeholder="Бүртэлтэй имэйл"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-center">
                                                                            <Button
                                                                                type="submit"
                                                                                variant="outlined"
                                                                                size="large"
                                                                                className={classes.myButton}
                                                                                // loading={loading}
                                                                            >
                                                                                {' '}
                                                                                <FormattedMessage
                                                                                    {...messages.getConfirmCode}
                                                                                />
                                                                            </Button>
                                                                        </div>
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                        ) : (
                                                            <Formik
                                                                key={'recoverForm'}
                                                                enableReinitialize={true}
                                                                initialValues={{
                                                                    otpCode: '',
                                                                    password: '',
                                                                    passwordConfirm: ''
                                                                }}
                                                                onSubmit={values => submitRecoverForm(values)}
                                                                validationSchema={RecoverSchema}>
                                                                {() => (
                                                                    <Form className="mt-8">
                                                                        {recoverSuccess && (
                                                                            <AlertLabel
                                                                                text={recoverSuccess}
                                                                                color="success"
                                                                                icon={['far', 'object-group']}
                                                                            />
                                                                        )}
                                                                        {recoverError && (
                                                                            <AlertLabel
                                                                                text={recoverError}
                                                                                color="warning"
                                                                                icon={['far', 'question-circle']}
                                                                            />
                                                                        )}

                                                                        {!doctorId ? (
                                                                            <>
                                                                                <div>
                                                                                    <div className="mb-3">
                                                                                        <FormInput
                                                                                            variant="outlined"
                                                                                            label="Нууц код"
                                                                                            name="otpCode"
                                                                                            type="text"
                                                                                            placeholder="Имэйл-р ирсэн 4 оронтой нууц код"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="mb-3">
                                                                                        <FormInput
                                                                                            variant="outlined"
                                                                                            label="Нууц үг"
                                                                                            name="password"
                                                                                            type="password"
                                                                                            placeholder="Бүртэлтэй имэйл"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="mb-3">
                                                                                        <FormInput
                                                                                            variant="outlined"
                                                                                            label="Нууц үг баталгаажуулах"
                                                                                            name="passwordConfirm"
                                                                                            type="password"
                                                                                            placeholder="Нууц үг баталгаажуулах"
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="text-center">
                                                                                    <Button
                                                                                        type="submit"
                                                                                        variant="outlined"
                                                                                        size="large"
                                                                                        className={classes.myButton}>
                                                                                        Нууц үг солих
                                                                                    </Button>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <div className="text-center">
                                                                                <Button
                                                                                    type="submit"
                                                                                    variant="outlined"
                                                                                    size="large"
                                                                                    className={classes.myButton}
                                                                                    onClick={() => {
                                                                                        history.push('/');
                                                                                    }}>
                                                                                    Нэвтрэх хуудас
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                        )}
                                                    </Card>
                                                </Grid>
                                            </div>
                                        </div>
                                        <div className="hero-footer pb-4">
                                            <Tooltip arrow title="Facebook">
                                                <IconButton
                                                    color="inherit"
                                                    size="medium"
                                                    variant="outlined"
                                                    className="text-white-50">
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'facebook']}
                                                        className="font-size-md"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip arrow title="Twitter">
                                                <IconButton
                                                    color="inherit"
                                                    size="medium"
                                                    variant="outlined"
                                                    className="text-white-50">
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'twitter']}
                                                        className="font-size-md"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip arrow title="Google">
                                                <IconButton
                                                    color="inherit"
                                                    size="medium"
                                                    variant="outlined"
                                                    className="text-white-50">
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'google']}
                                                        className="font-size-md"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip arrow title="Instagram">
                                                <IconButton
                                                    color="inherit"
                                                    size="medium"
                                                    variant="outlined"
                                                    className="text-white-50">
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'instagram']}
                                                        className="font-size-md"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex-fill"
                        style={{
                            backgroundImage: `url(${LoginBg})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}></div>
                </div>
            </div>
        </Fragment>
    );
}
