import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';

const FormError = ({ text }) => (
  <div>
    <MuiAlert className="mb-4" severity="warning">
      {text}
    </MuiAlert>
  </div>
);

export default FormError;
