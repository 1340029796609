import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { emchLogo, emchLogoText, isTelemedApp } from 'constants/CommonConstants';
import { pagePaths } from 'constants/PagePaths';

const HeaderLogo = props => {
  const { sidebarToggle, sidebarHover } = props;

  const renderLogo = () => {
    if (isTelemedApp) {
      return (
        <Fragment>
          {sidebarToggle ? (
            <img className="app-header-logo-img" alt="Telemed" src={emchLogo} />
          ) : (
            <img className="app-header-logo-img" alt="Telemed" src={emchLogoText} />
          )}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <img className="app-header-logo-img" alt="Telemed" src={emchLogo} />
        {!sidebarToggle && <img className="app-header-logo-img" alt="Telemed" src={emchLogoText} />}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover
        })}>
        <Box className="header-logo-wrapper" title="Telemed">
          <Link to={pagePaths.dashboard} className="header-logo-wrapper-link">
            <div style={{ display: 'flex' }}>
                { renderLogo() }
            </div>
          </Link>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);
