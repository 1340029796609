import React from 'react';
import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import VideocamIcon from '@material-ui/icons/Videocam';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { pagePaths } from 'constants/PagePaths';
import PostAddIcon from '@material-ui/icons/PostAdd';
import messages from '../../appMessages'

const incomeMessage = {...messages}
var iconsMap = {
    BarChartIcon: BarChartIcon,
    CalendarTodayIcon: CalendarTodayIcon,
    ChatIcon: ChatIcon,
    CodeIcon: CodeIcon,
    DashboardIcon: DashboardIcon,
    ErrorIcon: ErrorIcon,
    FolderIcon: FolderIcon,
    DashboardTwoToneIcon: DashboardTwoToneIcon,
    GradeTwoTone: GradeTwoTone,
    ListAltIcon: ListAltIcon,
    LockOpenIcon: LockOpenIcon,
    MailIcon: MailIcon,
    PresentToAllIcon: PresentToAllIcon,
    PeopleIcon: PeopleIcon,
    PersonIcon: PersonIcon,
    ReceiptIcon: ReceiptIcon,
    SettingsIcon: SettingsIcon,
    ViewModuleIcon: ViewModuleIcon,
    VideocamIcon: VideocamIcon,
    PostAddIcon: PostAddIcon,
    LocalHospitalIcon: LocalHospitalIcon
};

export default [
    {
        label: 'Үндсэн цэс',
        content: JSON.parse(
            `[
          {
            "label": "Хянах самбар",
            "translatemessage": "${incomeMessage.sidebarleftlistrow2.id}",  
            "defaultmessage": "${incomeMessage.sidebarleftlistrow2.defaultMessage}",
            "icon": "DashboardTwoToneIcon",
            "description": "Хянах самбар",
            "to": "${pagePaths.dashboard}"
          },
          {
            "label": "Үйлчлүүлэгч",
            "translatemessage": "${incomeMessage.sidebarleftlistrow3.id}",   
            "defaultmessage": "${incomeMessage.sidebarleftlistrow3.defaultMessage}",
            "icon": "ChatIcon",
            "description": "Үйлчлүүлэгчийн жагсаалт.",
            "to": "${pagePaths.chat}"
          },
          {
            "label": "Эмчийн жагсаалт",
            "translatemessage": "${incomeMessage.sidebarleftlistrow7.id}",   
            "defaultmessage": "${incomeMessage.sidebarleftlistrow4.defaultMessage}",
            "icon": "LocalHospitalIcon",
            "description": "Системд бүртгэлтэй бүх эмч нарын жагсаалт",
            "to": "${pagePaths.doctorList}"
          },     
          {
            "label": "Цаг захиалга",
            "translatemessage": "${incomeMessage.sidebarleftlistrow4.id}",   
            "defaultmessage": "${incomeMessage.sidebarleftlistrow4.defaultMessage}",
            "icon": "CalendarTodayIcon",
            "description": "Үйлчилгээний цаг захиалгын календарь",
            "to": "${pagePaths.calendar}"
          },
          {
            "label": "Тохиргоо",
            "translatemessage": "${incomeMessage.sidebarleftlistrow5.id}",  
            "defaultmessage": "${incomeMessage.sidebarleftlistrow5.defaultMessage}", 
            "icon": "SettingsIcon",
            "description": "Тохиргоо.",
            "to": "${pagePaths.settings}"
          },
          {
            "label": "Эмчийн блог",
            "translatemessage": "${incomeMessage.sidebarleftlistrow6.id}",  
            "defaultmessage": "${incomeMessage.sidebarleftlistrow6.defaultMessage}", 
            "icon": "PostAddIcon",
            "description": "Эмчийн пост үүсгэх хуудас.",
            "to": "${pagePaths.post}"
          }
        ]`,
            (key, value) => {
                if (key === 'icon') {
                    return iconsMap[value];
                } else {
                    return value;
                }
            }
        )
    }
];
