import React, { useEffect, createContext, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from 'constants/ApiConstants';
import { firebaseAuth, firebaseMessaging, firebaseDatabase, firebaseDBServerTimestamp } from 'utils/firebase';
import { publicFetch } from 'utils/PublicFetch';
import { apiPaths } from 'constants/ApiPaths';

import { pagePaths } from 'constants/PagePaths';

const saveMessagingDeviceToken = accessToken => {
  firebaseMessaging
    .getToken()
    .then(async function(currentToken) {
      if (currentToken) {
        const params= { 
          fcmToken: currentToken
        };

        await axios.post(`${API_BASE_URL}/doctor/fcmToken`, params, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        });
      } else {
        requestNotificationsPermissions(accessToken);
      }
    })
    .catch(function(error) {
      console.error('Unable to get messaging token.', error);
    });
};

const requestNotificationsPermissions = accessToken => {
  // TODO 11: Request permissions to send notifications.
  console.log('Requesting notifications permission...');
  firebaseMessaging
    .requestPermission()
    .then(function() {
      // Notification permission granted.
      saveMessagingDeviceToken(accessToken);
    })
    .catch(function(error) {
      console.error('Unable to get permission to notify.', error);
    });
};

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const tokenValue = localStorage.getItem('token');
  const userInfoValue = localStorage.getItem('userInfo');

  const [authState, setAuthState] = useState({
    token: tokenValue && tokenValue !== 'undefined' ? tokenValue : null,
    userInfo: userInfoValue && userInfoValue !== 'undefined' ? JSON.parse(userInfoValue) : {},
    presense: false
  });

  useEffect(() => {
    console.log('did mount currentUser:', firebaseAuth.currentUser);
    firebaseAuth.onAuthStateChanged(async firebaseUser => {
      console.log('auth change:', firebaseUser)
      if (firebaseUser) {
        const idToken = await firebaseUser.getIdToken();

        await fetchAndSetAuthInfo(idToken)

        //register token
        saveMessagingDeviceToken(idToken);

        //liseten online presense
        listenPresense(firebaseUser.uid)


      } else {
        //console.log('firebaseUser logged out');
      }
    });
  }, []);

  const setAuthInfo = ({ token, userInfo, presense }) => {
    localStorage.setItem('token', token);
    localStorage.setItem('userInfo', JSON.stringify(userInfo));

    setAuthState(prev => {
      return {
        ...prev, 
        token: token,
        userInfo: userInfo,
      }
    });
  };

  const fetchAndSetAuthInfo = async(idToken) => {
    publicFetch.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
    const {data} = await publicFetch.get(apiPaths.userInfo);

    const authData = {
      token: idToken,
      userInfo: data.result.userInfo,
      presense: false
    };

    setAuthInfo(authData)
  }

  const login = async ({username, password}) => {
    const params= {username, password}
    const { data } = await publicFetch.post(apiPaths.login, params);

    //firebase login
    const result = await firebaseAuth.signInWithCustomToken(data.result.accessToken)
    const firebaseUser = result.user
    console.log('login firebaseUser:', firebaseUser)

    const idToken = await firebaseUser.getIdToken()

    await fetchAndSetAuthInfo(idToken)
  }

  const logout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('doctorProfile');
    setAuthState({});

    try {
      await setPresense('offline')
      await firebaseAuth.signOut();
    } catch (error) {
      console.log('firebase logout error', error);
    }

    history.push(pagePaths.login);
  };

  const isAuthenticated = () => {
    if (!authState.token) {
      return false;
    }
    
    return true
  };

  const isAdmin = () => {
    return authState.userInfo.role === 'admin';
  };

  const setPresense = async (status) => {
    if (firebaseAuth.currentUser) {
      await firebaseDatabase.ref('/status/' + firebaseAuth.currentUser.uid).update({state: status, last_changed: firebaseDBServerTimestamp})
    }
  }

  const listenPresense = async (uid) => {
      const userStatusDatabaseRef = firebaseDatabase.ref('/status/' + uid);

      userStatusDatabaseRef.on('value', function(snapshot) {
        const val = snapshot.val()
        //console.log('presense changed: ', val)

        if (val) {
          setAuthState(prev => {
            return {...prev, presense: val.state}
          });
        }
      })

      const isOfflineForDatabase = {
          uid: uid,
          state: 'offline',
          last_changed: firebaseDBServerTimestamp,
      };
      
      var isOnlineForDatabase = {
          uid: uid,
          state: 'online',
          last_changed: firebaseDBServerTimestamp,
      };

      firebaseDatabase.ref('.info/connected').on('value', function(snapshot) {
        //console.log('.info/connected val:', snapshot.val())

        // If we're not currently connected, don't do anything.
        if (snapshot.val() == false) {
            return;
        };
    
        // If we are currently connected, then use the 'onDisconnect()' 
        // method to add a set which will only trigger once this 
        // client has disconnected by closing the app, 
        // losing internet, or any other means.
        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect() 
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
    
            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            userStatusDatabaseRef.set(isOnlineForDatabase);

            console.log('onDisconnect called')
        });
    });

  }

  return (
    <Provider
      value={{
        authState,
        setAuthState: authInfo => setAuthInfo(authInfo),
        login,
        logout,
        isAuthenticated,
        isAdmin,
        setPresense
      }}>
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
