require('@formatjs/intl-pluralrules/locale-data/en');
require('@formatjs/intl-pluralrules/locale-data/mn');
require('@formatjs/intl-pluralrules/locale-data/tr');
const AppLocaleList = [
    { name: 'English', code: 'en', lang: 'English' },    
    { name: 'Монгол', code: 'mn', lang: 'Mongolian' },    
    { name: 'Türkiye', code: 'tr', lang: 'Turkish' },    
];

// const addLocaleData = require('@formatjs/intl-pluralrules/polyfill'); 

// const en = require('@formatjs/intl-pluralrules/locale-data/en');
// const mn = require('@formatjs/intl-pluralrules/locale-data/mn');

// addLocaleData(en);
// addLocaleData(mn);

const enTranslationMessages = require('./translations/en.json');
const mnTranslationMessages = require('./translations/mn.json');
const trTranslationMessages = require('./translations/tr.json');

const translatedMessages = {
    en: enTranslationMessages,
    mn: mnTranslationMessages,
    tr: trTranslationMessages,
};

const DEFAULT_LOCALE = 'en';

export { AppLocaleList, translatedMessages, DEFAULT_LOCALE };