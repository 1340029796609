import React, { createContext, useContext } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { firebaseAuth, firebaseMessaging } from 'utils/firebase';
import { AuthContext } from './AuthContext';
import { API_BASE_URL } from 'constants/ApiConstants';

const FetchContext = createContext();
const { Provider } = FetchContext;

const getDecodedToken = token => {
    try {
        return jwtDecode(token);
    } catch (Error) {
        return null;
    }
};

const getIdToken = async () => {
    if (firebaseAuth.currentUser) {
        console.log('firebase user has token');
        const idToken = await firebaseAuth.currentUser.getIdToken();
        return idToken;
    }

    console.log('firebase user has no token!');
    return null;
};

const FetchProvider = ({ children }) => {
    const { authState, setAuthState } = useContext(AuthContext);
    const history = useHistory()

    const authAxios = axios.create({
        baseURL: API_BASE_URL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });

    authAxios.interceptors.request.use(
        async config => {
            let idToken = authState.token;
            const decodedIdToken = getDecodedToken(idToken);

            if (decodedIdToken) {
                const now = Math.floor(new Date().getTime() / 1000);
                if (decodedIdToken.exp <= now) {
                    console.log('token is expired. refreshing..');
                    //refresh token
                    idToken = await getIdToken();

                    //update token
                    setAuthState({ ...authState, token: idToken });
                }
            }
            
            config.headers.Authorization = `Bearer ${idToken}`;
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    authAxios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            const code = error && error.response ? error.response.status : 0;
            const message =
                error && error.response && error.response.data ? error.response.data.message : error.message;
            console.log('fetch context status code:', code, ' message:', message);

            if (code === 401 || code === 403) {
                console.log('auth error code', code);
                history.push('/')
                return Promise.reject({ ...error, code: code, message: message });
            }
            return Promise.reject({ ...error, code: code, message: message });
        }
    );

    return (
        <Provider
            value={{
                authAxios
            }}>
            {children}
        </Provider>
    );
};

export { FetchContext, FetchProvider };
