import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Container, Box, Typography, Card, CardContent } from '@material-ui/core';
import RegisterForm from 'components/PagesRegister/RegisterForm';
import LoginBg from '../../../assets/images/register.png';
import { FormattedMessage } from 'react-intl';
import messages from '../../../appMessages';
import SwitchLanguage from 'components/SwitchLanguage';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box p={0}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const LivePreviewExample = () => {
    const [value, setValue] = React.useState(0);

    return (
        <Fragment>
            <div className="app-wrapper min-vh-100 bg-white">
                <div className="app-main d-flex">
                    <div className="app-content p-0 flex-fill">
                        <div className="d-flex flex-row-reverse">
                            <SwitchLanguage />
                        </div>
                        <div className="app-inner-content-layout--main">
                            <div className="flex-grow-1 d-flex align-items-center">
                                <div className="bg-composed-wrapper--content">
                                    <Grid container spacing={0} className="min-vh-100" justify="center">
                                        <Grid item xs={12} md={8} lg={7} className="d-flex align-items-center">
                                            <Container maxWidth="sm">
                                                <TabPanel value={value} index={0}>
                                                    <h3
                                                        className="display-3 text-xl-left text-center mb-3 font-weight-bold"
                                                        style={{ color: '#079BAB' }}>
                                                        <FormattedMessage {...messages.register} />
                                                    </h3>
                                                    <Card className="mx-0 bg-secondary mt-0 w-100 p-0 mb-4 border-0">
                                                        <CardContent className="p-3">
                                                            <RegisterForm />
                                                        </CardContent>
                                                    </Card>
                                                </TabPanel>
                                            </Container>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex-fill"
                        style={{
                            backgroundImage: `url(${LoginBg})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}></div>
                </div>
            </div>
        </Fragment>
    );
};

export default LivePreviewExample;
