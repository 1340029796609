import React, { Fragment, useContext } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Hidden, IconButton, AppBar, Box, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { setSidebarToggle, setSidebarToggleMobile } from 'reducers/ThemeOptions';
import HeaderLogo from '../../layout-components/HeaderLogo';
import HeaderDots from '../../layout-components/HeaderDots';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { pagePaths } from 'constants/PagePaths';
import HeaderSearch from 'layout-components/HeaderSearch';
import messages from '../../appMessages';
import { CallContext } from 'context/CallContext';
import SwitchLanguage from 'components/SwitchLanguage';
import { emchLogo, emchLogoText, isTelemedApp } from 'constants/CommonConstants';

const Header = props => {
  const callContext = useContext(CallContext);
  const { callList, acceptCall, cancelCall } = callContext;

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle
  } = props;

  const renderLogo = () => {
    if (isTelemedApp) {
      return (
        <Fragment>
          {sidebarToggle ? (
            <img className="app-header-logo-img" alt="Telemed" src={emchLogo} />
          ) : (
            <img className="app-header-logo-img" alt="Telemed" src={emchLogoText} />
          )}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <img className="app-header-logo-img" alt="Telemed" src={emchLogo} />
        {!sidebarToggle && <img className="app-header-logo-img" alt="Telemed" src={emchLogoText} />}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx('app-header', {
          'app-header-collapsed-sidebar': props.isCollapsedLayout
        })}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 3}>
        {!props.isCollapsedLayout && <HeaderLogo />}
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box className="app-logo-wrapper" title="Telemed">
              <Link to={pagePaths.dashboard} className="app-logo-link">
                <div style={{ display: 'flex' }}>
                  { renderLogo() }
                </div>
              </Link>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              {!props.isCollapsedLayout ? (
                <Box
                  className={clsx('btn-toggle-collapse', {
                    'btn-toggle-collapse-closed': sidebarToggle
                  })}>
                  <Tooltip
                    title={
                      sidebarToggle ? <FormattedMessage {...messages.open} /> : <FormattedMessage {...messages.close} />
                    }
                    placement="right">
                    <IconButton color="inherit" onClick={toggleSidebar} size="medium" className="btn-inverse">
                      {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <HeaderSearch />
              )}
            </Box>
          </Hidden>
          <Box className="d-flex align-items-center">
            <div className="ml-5">
              <SwitchLanguage />
            </div>
            <HeaderDots />
            <HeaderUserbox />
            <Box className="toggle-sidebar-btn-mobile">
              <Tooltip title={'Дэлгэх'} placement="right">
                <IconButton color="inherit" onClick={toggleSidebarMobile} size="medium">
                  {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AppBar>

      {callList && callList.length > 0 ? (
        <div style={{ position: 'fixed', top: '100px', right: '50px', color: 'transparent', zIndex: 3000 }}>
          {callList.map(liveCall => {
            return (
              <div
                style={{
                  padding: '12px 20px 12px 20px',
                  marginBottom: '10px',
                  backgroundColor: '#4caf50',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow:
                    '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
                }}>
                <div style={{ color: 'white', padding: '0px 15px 0px 0px' }}>
                  <span style={{ fontWeight: '500' }}>{liveCall.customerName} </span>
                  -с дуудлага ирж байна!
                </div>
                <div>
                  <button onClick={() => acceptCall(liveCall.liveCallId)}>Авах</button>
                  <button onClick={() => cancelCall(liveCall.liveCallId)} style={{ marginLeft: '8px' }}>
                    Таслах
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        ''
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
