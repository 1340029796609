/*
 * App Messages
 *
 * This contains all the text for the App component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'reactlocalization.app';

export default defineMessages({
  income: {
    id: `${scope}.dash.income`,
    defaultMessage: 'Орлого'
  },
  countOrders: {
    id: `${scope}.dash.order.count`,
    defaultMessage: 'Нийт цаг захиалгын тоо'
  },
  totalTime: {
    id: `${scope}.dash.time.total`,
    defaultMessage: 'Уулзалт хийсэн нийт хугацаа'
  },
  totalCustomer: {
    id: `${scope}.dash.customer.total`,
    defaultMessage: 'Нийт үйлчилүүлэгч'
  },
  sidebarleftlistrow2: {
    id: `${scope}.sidebar.left.list.row2`,
    defaultMessage: 'Хянах самбар'
  },
  sidebarleftlistrow3: {
    id: `${scope}.sidebar.left.list.row3`,
    defaultMessage: 'Мессеж бичих'
  },
  sidebarleftlistrow4: {
    id: `${scope}.sidebar.left.list.row4`,
    defaultMessage: 'Цаг захиалга'
  },
  sidebarleftlistrow5: {
    id: `${scope}.sidebar.left.list.row5`,
    defaultMessage: 'Тохиргоо'
  },
  sidebarleftlistrow6: {
    id: `${scope}.sidebar.left.list.row6`,
    defaultMessage: 'Эмчийн блог'
  },
  sidebarleftlistrow7: {
    id: `${scope}.sidebar.left.list.row7`,
    defaultMessage: 'Эмчийн жагсаалт'
  },
  headernotification: { id: `${scope}.header.notification`, defaultMessage: 'Мэдэгдэл' },
  headerextraboxrightrow1: {
    id: `${scope}.header.extrabox.right.row1`,
    defaultMessage: 'Эмчийн профайл'
  },
  headerextraboxrightrow2: {
    id: `${scope}.header.extrabox.right.row2`,
    defaultMessage: 'Системээс гарах'
  },
  headerextraboxrightCheckDevice: {
    id: `${scope}.header.extrabox.right.checkDevice`,
    defaultMessage: 'Төхөөрөмж шалгах'
  },
  headerextraboxrightrow3: {
    id: `${scope}.header.extrabox.right.row3`,
    defaultMessage: 'Онлайн төлөв'
  },
  headerextraboxrightrow3statusonline: {
    id: `${scope}.header.extrabox.right.row3.status.online`,
    defaultMessage: 'Нээлттэй'
  },
  headerextraboxrightrow3statusoffline: {
    id: `${scope}.header.extrabox.right.row3.status.offline`,
    defaultMessage: 'Хаалттай'
  },
  sidebarleftheader: { id: `${scope}.sidebar.left.header`, defaultMessage: 'Үндсэн цэс' },
  dashboardhomeheader: {
    id: `${scope}.dashboard.home.header`,
    defaultMessage: 'Хянах самбар'
  },
  dashboardhomeheaderrow2: {
    id: `${scope}.dashboard.home.header.row2`,
    defaultMessage: 'Тайлан болон статистик тоон мэдээ'
  },
  dashboardhomebox1: {
    id: `${scope}.dashboard.home.box1`,
    defaultMessage: 'Үзлэгийн календарь'
  },
  dashboardhomebox1row2: {
    id: `${scope}.dashboard.home.box1.row2`,
    defaultMessage: 'Цаг захиалгын дагуу'
  },
  dashboardhomebox2: {
    id: `${scope}.dashboard.home.box2`,
    defaultMessage: 'Нийт үзсэн хүний тоо'
  },
  dashboardhomebox3: {
    id: `${scope}.dashboard.home.box3.`,
    defaultMessage: 'Эмчилгээ, зөвлөгөө бичсэн хүний тоо'
  },
  dashboardhomebox4: {
    id: `${scope}.dashboard.home.box4`,
    defaultMessage: 'Нэг удаагийн үзлэг хийсэн'
  },
  dashboardhomebox4row2: {
    id: `${scope}.dashboard.home.box4.row2`,
    defaultMessage: 'Нэг удаагийн үзлэг хийсэн хүний тоо'
  },
  dashboardhomebox5: {
    id: `${scope}.dashboard.home.box5`,
    defaultMessage: 'Давтан үзэх шаардлагатай'
  },
  dashboardhomebox5box2: {
    id: `${scope}.dashboard.home.box5.box2`,
    defaultMessage: 'Давтан үзэх шаардлагатай өвчтний тоо'
  },
  dashboardhomebox6: {
    id: `${scope}.dashboard.home.box6`,
    defaultMessage: 'Давтан үзсэн'
  },
  dashboardhomebox6row2: {
    id: `${scope}.dashboard.home.box6.row2`,
    defaultMessage: 'Нийт давтан үзсэн өвчтний тоо'
  },
  dashboardhomebox7: {
    id: `${scope}.dashboard.home.box7`,
    defaultMessage: 'Байнгын хянах'
  },
  dashboardhomebox7row2: {
    id: `${scope}.dashboard.home.box7.row2.`,
    defaultMessage: 'Байнгын хянах өвчтний тоо'
  },
  dashboardhomeperiod: {
    id: `${scope}.dashboard.home.period`,
    defaultMessage: 'Сүүлийн 7 хоног'
  },
  dashboardhomeperiod2: {
    id: `${scope}.dashboard.home.period2`,
    defaultMessage: 'Сүүлийн 30 хоног'
  },
  chathomesidebarleftheader: {
    id: `${scope}.chat.home.sidebar.left.header`,
    defaultMessage: 'ҮЙЛЧЛҮҮЛЭГЧИД'
  },
  chathomesidebarleftifchatisempty: {
    id: `${scope}.chat.home.sidebar.left.if.chat.is.empty`,
    defaultMessage: 'Одоогоор хоосон байна'
  },
  chathomesidebarrightheader: {
    id: `${scope}.chat.home.sidebar.right.header`,
    defaultMessage: 'БАЙНГА ХЯНАХ ҮЙЛЧЛҮҮЛЭГЧ'
  },
  chathomesidebarrightbox1: {
    id: `${scope}.chat.home.sidebar.right.box1`,
    defaultMessage: 'ХУВИЙН МЭДЭЭЛЭЛ'
  },
  chathomesidebarrightbox1box1: {
    id: `${scope}.chat.home.sidebar.right.box1.box1`,
    defaultMessage: 'ХУВИЙН МЭДЭЭЛЭЛ'
  },
  chathomesidebarrightbox1box2: {
    id: `${scope}.chat.home.sidebar.right.box1.box2`,
    defaultMessage: 'Эмчийн тэмдэглэл үүсгэх'
  },
  chathomesidebarrightbox1box3: {
    id: `${scope}.chat.home.sidebar.right.box1.box3`,
    defaultMessage: 'Нас'
  },
  chathomesidebarrightbox1box4: {
    id: `${scope}.chat.home.sidebar.right.box1.box4`,
    defaultMessage: 'Хүйс'
  },
  chathomesidebarrightbox1box5: {
    id: `${scope}.chat.home.sidebar.right.box1.box5`,
    defaultMessage: 'РД'
  },
  chathomesidebarrightbox1box6: {
    id: `${scope}.chat.home.sidebar.right.box1.box6`,
    defaultMessage: 'Ажил эрхлэлт'
  },
  chathomesidebarrightbox1box7: {
    id: `${scope}.chat.home.sidebar.right.box1.box7`,
    defaultMessage: ' Ажилладаг байгууллага'
  },
  chathomesidebarrightbox1box8: {
    id: `${scope}.chat.home.sidebar.right.box1.box8`,
    defaultMessage: ' Албан тушаал'
  },
  chathomesidebarrightbox1box9: {
    id: `${scope}.chat.home.sidebar.right.box1.box9`,
    defaultMessage: 'Ажлын нөхцөл'
  },
  chathomesidebarrightbox1box10: {
    id: `${scope}.chat.home.sidebar.right.box1.box10`,
    defaultMessage: 'Боловсрол'
  },
  chathomesidebarrightbox1box11: {
    id: `${scope}.chat.home.sidebar.right.box1.box11`,
    defaultMessage: 'Мэргэжил'
  },
  chathomesidebarrightbox1box12: {
    id: `${scope}.chat.home.sidebar.right.box1.box12`,
    defaultMessage: 'Боловсролын зэрэг'
  },
  chathomesidebarrightbox1box13: {
    id: `${scope}.chat.home.sidebar.right.box1.box13`,
    defaultMessage: 'Оршин суугаа хаяг'
  },
  chathomesidebarrightbox1box14: {
    id: `${scope}.chat.home.sidebar.right.box1.box14`,
    defaultMessage: 'zipcode'
  },
  chathomesidebarrightbox1box15: {
    id: `${scope}.chat.home.sidebar.right.box1.box15`,
    defaultMessage: ' Дэлгэрэнгүй хаяг:'
  },
  chathomesidebarrightbox2: {
    id: `${scope}.chat.home.sidebar.right.box2`,
    defaultMessage: 'ЭРҮҮЛ МЭНДИЙН МЭДЭЭЛЭЛ'
  },
  chathomesidebarrightbox2box1: {
    id: `${scope}.chat.home.sidebar.right.box2.box1`,
    defaultMessage: 'ЭРҮҮЛ МЭНДИЙН МЭДЭЭЛЭЛ'
  },
  chathomesidebarrightbox2box2: {
    id: `${scope}.chat.home.sidebar.right.box2.box2`,
    defaultMessage: 'Эмчийн тэмдэглэл үүсгэх'
  },
  chathomesidebarrightbox2box3: {
    id: `${scope}.chat.home.sidebar.right.box2.box3`,
    defaultMessage: 'Өндөр'
  },
  chathomesidebarrightbox2box4: {
    id: `${scope}.chat.home.sidebar.right.box2.box4`,
    defaultMessage: 'Жин'
  },
  chathomesidebarrightbox2box5: {
    id: `${scope}.chat.home.sidebar.right.box2.box5`,
    defaultMessage: 'Цусны бүлэг'
  },
  chathomesidebarrightbox2box6: {
    id: `${scope}.chat.home.sidebar.right.box2.box6`,
    defaultMessage: 'Хооллолтын байдал'
  },
  chathomesidebarrightbox2box7: {
    id: `${scope}.chat.home.sidebar.right.box2.box7`,
    defaultMessage: 'Архи хэрэглэдэг эсэх'
  },
  chathomesidebarrightbox2box8: {
    id: `${scope}.chat.home.sidebar.right.box2.box8`,
    defaultMessage: 'Тамхи татдаг эсэх'
  },
  chathomesidebarrightbox2box9: {
    id: `${scope}.chat.home.sidebar.right.box2.box9`,
    defaultMessage: 'Удамших өвчин'
  },
  chathomesidebarrightbox2box10: {
    id: `${scope}.chat.home.sidebar.right.box2.box10`,
    defaultMessage: 'Харшилдаг зүйл'
  },
  chathomesidebarrightbox2box11: {
    id: `${scope}.chat.home.sidebar.right.box2.box11`,
    defaultMessage: ' Эм тариа'
  },
  chathomesidebarrightbox2box12: {
    id: `${scope}.chat.home.sidebar.right.box2.box12`,
    defaultMessage: 'Хоол хүнс'
  },
  chathomesidebarrightbox2box13: {
    id: `${scope}.chat.home.sidebar.right.box2.box13`,
    defaultMessage: ' Бусад'
  },
  chathomesidebarrightbox3box15: {
    id: `${scope}.chat.home.sidebar.right.box2.box15`,
    defaultMessage: ' Эмэгтэйчүүд'
  },
  chathomesidebarrightbox3box16: {
    id: `${scope}.chat.home.sidebar.right.box2.box16`,
    defaultMessage: ' Жирэмслэлт'
  },
  chathomesidebarrightbox3box17: {
    id: `${scope}.chat.home.sidebar.right.box2.box17`,
    defaultMessage: ' Хүүхэд'
  },
  chathomesidebarrightbox3box18: {
    id: `${scope}.chat.home.sidebar.right.box2.box18`,
    defaultMessage: ' Үр хөндүүлсэн байдал'
  },
  chathomesidebarrightbox3box19: {
    id: `${scope}.chat.home.sidebar.right.box2.box19`,
    defaultMessage: ' Он'
  },
  chathomesidebarrightbox3box20: {
    id: `${scope}.chat.home.sidebar.right.box2.box20`,
    defaultMessage: ' Шалтгаан'
  },
  chathomesidebarrightbox3box21: {
    id: `${scope}.chat.home.sidebar.right.box2.box21`,
    defaultMessage: ' Мэс ажилбарт орсон байдал'
  },
  chathomesidebarrightbox3box22: {
    id: `${scope}.chat.home.sidebar.right.box2.box22`,
    defaultMessage: ' Эмнэлэг'
  },
  chathomesidebarrightbox3box23: {
    id: `${scope}.chat.home.sidebar.right.box2.box23`,
    defaultMessage: ' Эмч'
  },
  chathomesidebarrightbox3box24: {
    id: `${scope}.chat.home.sidebar.right.box2.box24`,
    defaultMessage: ' Төрөлт'
  },
  chathomesidebarrightbox3box25: {
    id: `${scope}.chat.home.sidebar.right.box2.box25`,
    defaultMessage: ' Кэсер хийлгэсэн эхэх'
  },
  chathomesidebarrightbox3box26: {
    id: `${scope}.chat.home.sidebar.right.box2.box26`,
    defaultMessage: ' Арга'
  },
  chathomesidebarrightbox3box27: {
    id: `${scope}.chat.home.sidebar.right.box2.box27`,
    defaultMessage: 'Мэс ажилбар'
  },
  chathometsidebarrightbox3: {
    id: `${scope}.chat.homet.sidebar.right.box3`,
    defaultMessage: 'ЭМЧИЙН ТЭМДЭГЛЭЛ'
  },
  chathomesidebarrightbox3box1: {
    id: `${scope}.chat.home.sidebar.right.box3.box1`,
    defaultMessage: 'ЭМЧИЙН ТЭМДЭГЛЭЛ'
  },
  chathomesidebarrightbox3box2: {
    id: `${scope}.chat.home.sidebar.right.box3.box2`,
    defaultMessage: 'Эмчийн тэмдэглэл үүсгэх'
  },
  chathomesidebarrightbox4: {
    id: `${scope}.chat.home.sidebar.right.box4`,
    defaultMessage: 'ШИНЖИЛГЭЭНИЙ ХАРИУ'
  },
  chathomesidebarrightbox4box1: {
    id: `${scope}.chat.home.sidebar.right.box4.box1`,
    defaultMessage: 'ШИНЖИЛГЭЭНИЙ ХАРИУ'
  },
  chathomesidebarrightbox4ifempty: {
    id: `${scope}.chat.home.sidebar.right.box4.if.empty`,
    defaultMessage: 'Нийт: 0 файл'
  },
  chathomesidebarleftfooter: {
    id: `${scope}.chat.home.sidebar.left.footer`,
    defaultMessage: 'Тун удахгүй уулзалт:'
  },
  chathomesidebarleftfooterAppointments: {
    id: `${scope}.chat.home.sidebar.left.footer.appointment`,
    defaultMessage: ' Одоогоор цаг захиалга байхгүй байна'
  },
  appointmentbookinghomeheader: {
    id: `${scope}.appointment.booking.home.header`,
    defaultMessage: 'Цаг захиалга'
  },
  appointmentbookinghomeheaderrow2: {
    id: `${scope}.appointment.booking.home.header.row2`,
    defaultMessage: 'Үйлчилгээний цаг захиалгын календарь'
  },
  settingsexamination_settingsheader: {
    id: `${scope}.settings.examination_settings.header`,
    defaultMessage: 'Үзлэгийн тохиргоо'
  },
  settingsexamination_settingsbox1name: {
    id: `${scope}.settings.examination_settings.box1.name`,
    defaultMessage: 'Үзлэгийн төрөл'
  },
  settingsexamination_settingsbox2name: {
    id: `${scope}.settings.examination_settings.box2.name`,
    defaultMessage: 'Төлбөр'
  },
  settingsexamination_settingsbox3name: {
    id: `${scope}.settings.examination_settings.box3.name`,
    defaultMessage: 'Үргэлжлэх хугацаа'
  },
  settingsexamination_settingsbox4: {
    id: `${scope}.settings.examination_settings.box4.`,
    defaultMessage: 'Хадгалах'
  },
  settingsschedule_settingsheader: {
    id: `${scope}.settings.schedule_settings.header`,
    defaultMessage: 'Цагийн хуваарийн тохиргоо'
  },
  settingsschedule_settingsheading: {
    id: `${scope}.settings.schedule_settings.heading`,
    defaultMessage: 'Цагийн хуваарийн тохиргоо'
  },
  settingsschedule_settingsworking_hour: {
    id: `${scope}.settings.schedule_settings.working_hour`,
    defaultMessage: 'Ажлын цаг'
  },
  settingsschedule_settingsworking_hourmonday: {
    id: `${scope}.settings.schedule_settings.working_hour.monday`,
    defaultMessage: 'Даваа'
  },
  settingsschedule_settingsworking_hourstuesday: {
    id: `${scope}.settings.schedule_settings.working_hours.tuesday`,
    defaultMessage: 'Мягмар'
  },
  'settingsschedule_settingsworking_hourwednesday`': {
    id: `${scope}.settings.schedule_settings.working_hour.wednesday`,
    defaultMessage: 'Лхагва'
  },
  settingsschedule_settingsworking_hourthursday: {
    id: `${scope}.settings.schedule_settings.working_hour.thursday`,
    defaultMessage: 'Пүрэв'
  },
  settingsschedule_settingsworking_hourfriday: {
    id: `${scope}.settings.schedule_settings.working_hour.friday`,
    defaultMessage: 'Баасан'
  },
  settingsschedule_settingsworking_hoursaturday: {
    id: `${scope}.settings.schedule_settings.working_hour.saturday`,
    defaultMessage: 'Бямба'
  },
  settingsschedule_settingsworking_hoursunday: {
    id: `${scope}.settings.schedule_settings.working_hour.sunday`,
    defaultMessage: 'Ням'
  },
  settingspersonalinformationheader: {
    id: `${scope}.settings.personal.information.header`,
    defaultMessage: 'Хувийн мэдээлэл'
  },
  settingspersonalinformationyellow_box1: {
    id: `${scope}.settings.personal.information.yellow_box1`,
    defaultMessage: 'Үндсэн мэдээлэл засах'
  },
  settingspersonalinformationyellow_box2: {
    id: `${scope}.settings.personal.information.yellow_box2`,
    defaultMessage: 'Нууц үг солих'
  },
  settingspersonalinformationyellow_box3: {
    id: `${scope}.settings.personal.information.yellow_box3`,
    defaultMessage: 'Урилга илгээх код'
  },
  settingspersonalinformationyellow_box3_row2: {
    id: `${scope}.settings.personal.information.yellow_box3_row2`,
    defaultMessage: 'Бүртгэгдсэн он сар өдөр'
  },
  settingspersonalinformationyellow_box4: {
    id: `${scope}.settings.personal.information.yellow_box4`,
    defaultMessage: 'Профайл засах'
  },
  settingspersonalinformationyellow_box4editbox1title: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box1.title`,
    defaultMessage: 'Төрсөн он сар өдөр'
  },
  settingspersonalinformationyellow_box4editbox2title: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box2.title`,
    defaultMessage: 'Хүйс'
  },
  settingspersonalinformationyellow_box4editbox2choice1: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box2.choice1`,
    defaultMessage: 'Эрэгтэй'
  },
  settingspersonalinformationyellow_box4editbox2choice2: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box2.choice2`,
    defaultMessage: 'Эмэгтэй'
  },
  settingspersonalinformationyellow_box4editbox3title: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box3.title`,
    defaultMessage: 'Миний тухай'
  },
  settingspersonalinformationyellow_box4editbox4title: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box4.title`,
    defaultMessage: 'Байршил'
  },
  settingspersonalinformationyellow_box4editbox5title: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box5.title`,
    defaultMessage: 'Ажилласан жил'
  },
  settingspersonalinformationyellow_box4editbox6title: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box6.title`,
    defaultMessage: 'Зэрэг дэв'
  },
  settingspersonalinformationyellow_box4editbox7title: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box7.title`,
    defaultMessage: 'Мэргэжил'
  },
  settingspersonalinformationyellow_box4editbox8title: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box8.title`,
    defaultMessage: 'Ажлын туршлага'
  },
  settingspersonalinformationyellow_box4editbox8column1: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box8.column1`,
    defaultMessage: 'Actions'
  },
  settingspersonalinformationyellow_box4editbox8column2: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box8.column2`,
    defaultMessage: 'Хаана'
  },
  settingspersonalinformationyellow_box4editbox8column3: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box8.column3`,
    defaultMessage: 'Хэзээ'
  },
  settingspersonalinformationyellow_box4editbox8column4: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box8.column4`,
    defaultMessage: 'Албан тушаал'
  },
  settingspersonalinformationyellow_box4editbox9title: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box9.title`,
    defaultMessage: 'Боловсрол'
  },
  settingspersonalinformationyellow_box4editbox9column1: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box9.column1`,
    defaultMessage: 'Actions'
  },
  settingspersonalinformationyellow_box4editbox9column2: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box9.column2`,
    defaultMessage: 'Хаана'
  },
  settingspersonalinformationyellow_box4editbox9column3: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box9.column3`,
    defaultMessage: 'Хэзээ'
  },
  settingspersonalinformationyellow_box4editbox9column4: {
    id: `${scope}.settings.personal.information.yellow_box4.edit.box9.column4`,
    defaultMessage: 'Болосврол'
  },
  blogheader: { id: `${scope}.blog.header`, defaultMessage: 'Блог' },
  blogheaderrow2: {
    id: `${scope}.blog.header.row2`,
    defaultMessage: 'Эмчийн блог удирдах хуудас'
  },
  blogTableHeader: {
    id: `${scope}.blog.table.header`,
    defaultMessage: 'Зөвлөгөөний жагсаалт'
  },
  blogTableId: { id: `${scope}.blog.table.id`, defaultMessage: 'Дугаар' },
  blogTableTitle: { id: `${scope}.blog.table.title`, defaultMessage: 'Гарчиг' },
  blogTableDescription: { id: `${scope}.blog.table.description`, defaultMessage: 'Тайлбар' },
  blogTableCreatedAt: { id: `${scope}.blog.table.createdAt`, defaultMessage: 'Огноо' },
  blogTableEdit: { id: `${scope}.blog.table.edit`, defaultMessage: 'Засах' },
  blogTableView: { id: `${scope}.blog.table.view`, defaultMessage: 'Үзэх' },
  blogTableDelete: { id: `${scope}.blog.table.delete`, defaultMessage: 'Устгах' },
  blogTablePublished: {
    id: `${scope}.blog.table.published`,
    defaultMessage: 'Нийтлэгдсэн эсэх'
  },
  blogTableAction: {
    id: `${scope}.blog.table.action`,
    defaultMessage: 'Үйлдэл'
  },
  blogblue_box: { id: `${scope}.blog.blue_box`, defaultMessage: 'Нийтлэл нэмэх' },
  blogblue_boxeditbox1: {
    id: `${scope}.blog.blue_box.edit.box1`,
    defaultMessage: 'Нийтлэхгүй хадгалах'
  },
  blogblue_boxeditbox2: { id: `${scope}.blog.blue_box.edit.box2`, defaultMessage: 'Нийтлэх' },
  blogblue_boxeditbox3title: {
    id: `${scope}.blog.blue_box.edit.box3.title`,
    defaultMessage: 'Гарчиг'
  },
  blogblue_boxeditbox4title: {
    id: `${scope}.blog.blue_box.edit.box4.title`,
    defaultMessage: 'Блог ангилал'
  },
  blogblue_boxeditbox5inside_boxwarning: {
    id: `${scope}.blog.blue_box.edit.box5.inside_box.warning`,
    defaultMessage: 'Нүүр зураг оруулах. Хамгийн ихдээ 5мб хэмжээтэй зураг байна.'
  },
  blogblue_boxeditbox6title: {
    id: `${scope}.blog.blue_box.edit.box6.title`,
    defaultMessage: 'Тайлбар'
  },
  blogblue_boxeditbox7warning: {
    id: `${scope}.blog.blue_box.edit.box7.warning`,
    defaultMessage: 'Орхиж болно. Энэ тайлбар хэрэглэгчид харагдахгүй. Та өөрөө тэмдэглэл байдлаар ашиглаж болно.'
  },
  blogblue_boxeditbox8inside_text: {
    id: `${scope}.blog.blue_box.edit.box8.inside_text`,
    defaultMessage: 'Нэмэх товч дээр дарж гарчиг, жагсаалт болон зураг сонгоорой'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice1: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice1`,
    defaultMessage: 'Том гарчиг'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice2: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice2`,
    defaultMessage: 'Дунд гарчиг'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice3: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice3`,
    defaultMessage: 'Жижиг гарчиг'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice4: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice4`,
    defaultMessage: 'Дөрвөлжинтэй жагсаалт'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice5: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice5`,
    defaultMessage: 'Цэгтэй жагсаалт'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice6: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice6`,
    defaultMessage: 'Дугаартай жагсаалт'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice7: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice7`,
    defaultMessage: 'Хүснэгт'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice8: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice8`,
    defaultMessage: 'Ишлэл'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice9: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice9`,
    defaultMessage: 'Code block'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice10: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice10`,
    defaultMessage: 'Хуваах шугам'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice11: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice11`,
    defaultMessage: 'Зураг'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice12: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice12`,
    defaultMessage: 'Холбоос'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice13: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice13`,
    defaultMessage: 'Мэдээлэл'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice14: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice14`,
    defaultMessage: 'Анхааруулга'
  },
  blogblue_boxeditbox8if_click_plus_buttonchoice15: {
    id: `${scope}.blog.blue_box.edit.box8.if_click_plus_button.choice15`,
    defaultMessage: 'Зөвлөмж'
  },
  blogblue_boxeditbox9: {
    id: `${scope}.blog.blue_box.edit.box9`,
    defaultMessage: 'Нийтлэхгүй хадгалах'
  },
  blogblue_boxeditbox10: {
    id: `${scope}.blog.blue_box.edit.box10`,
    defaultMessage: 'Нийтлэх'
  },
  blogblue_boxeditbox21: {
    id: `${scope}.blog.blue_box.edit.box21`,
    defaultMessage: 'Unpublish'
  },
  willTranslate: {
    id: `${scope}.will.translate`,
    defaultMessage: 'def'
  },
  noDoctorNote: {
    id: `${scope}.will.translate`,
    defaultMessage: 'def'
  },
  davtanUzegHiihEseh: {
    id: `${scope}.customer.medicalRecord.davtanUzegHiihEseh`,
    defaultMessage: 'Давтан үзлэг хийх эсэх'
  },
  baingiinHyaltandBailgahEseh: {
    id: `${scope}.customer.medicalRecord.baingiinHyanaltEseh`,
    defaultMessage: 'БАЙНГЫН ХЯНАЛТАНД БАЙЛГАХ ЭС22ЭХ'
  },
  emchiinTemdeglelHaahEseh: {
    id: `${scope}.customer.medicalRecord.emchiinTemdeglelHaahEseh`,
    defaultMessage: 'ЭМЧИЙН ТЭМДЭГЛЭЛ ХААХ '
  },
  zoviur: {
    id: `${scope}.customer.medicalRecord.zoviur`,
    defaultMessage: 'Зовиур'
  },
  asuumj: {
    id: `${scope}.customer.medicalRecord.asuumj`,
    defaultMessage: 'Асуумж'
  },
  uzlegiinTemdeglel: {
    id: `${scope}.customer.medicalRecord.uzlegiinTemdeglel`,
    defaultMessage: 'Үзлэгийн тэмдэглэл'
  },
  uguhShinjilgeeniiJagsaalt: {
    id: `${scope}.customer.medicalRecord.uguhShinjilgeeniiJagsaalt`,
    defaultMessage: 'Өгөх шинжилгээний жагсаалт'
  },
  shinjilgeeniiTuluvluguuNemeh: {
    id: `${scope}.customer.medicalRecord.shinjilgeeniiTuluvluguuNemeh`,
    defaultMessage: 'Шинжилгээний төлөвлөгөө шинээр нэмэх'
  },
  uridchilsanOnosh: {
    id: `${scope}.customer.medicalRecord.uridchilsanOnosh`,
    defaultMessage: 'Урьдчилсан онош'
  },
  dagaldahOnosh: {
    id: `${scope}.customer.medicalRecord.dagaldahOnosh`,
    defaultMessage: 'Дагалдах онош '
  },
  yalganOnosh: {
    id: `${scope}.customer.medicalRecord.yalganOnosh`,
    defaultMessage: 'Ялган онош '
  },
  emiinBusEmchilgee: {
    id: `${scope}.customer.medicalRecord.emiinBusEmchilgee`,
    defaultMessage: 'Эмийн бус эмчилгээ'
  },
  hoollolt: {
    id: `${scope}.customer.medicalRecord.hoollolt`,
    defaultMessage: 'Хооллолт'
  },
  emiinJor: {
    id: `${scope}.customer.medicalRecord.emiinJor`,
    defaultMessage: 'Эмийн жор'
  },
  editEmchiinTemdeglel: {
    id: `${scope}.customers.doctorNote.editEmchiinTemdeglel`,
    defaultMessage: 'Эмчийн тэмдэглэл засах'
  },
  createEmchiinTemdeglel: {
    id: `${scope}.customers.doctorNote.createEmchiinTemdeglel`,
    defaultMessage: 'Эмчийн тэмдэглэл үүсгэх'
  },
  createNewjor: {
    id: `${scope}.customer.medicalRecord.createNewjor`,
    defaultMessage: 'ЭМИЙН ЖОР ШИНЭЭР НЭМЭХ'
  },
  settingPersonalNas: {
    id: `${scope}.settings.personal.nas`,
    defaultMessage: 'Настай'
  },
  settingPersonalTaniCode: {
    id: `${scope}.settings.personal.code`,
    defaultMessage: 'Таны код'
  },
  settingPersonalCopy: {
    id: `${scope}.settingPersonalCopy`,
    defaultMessage: 'Хуулах'
  },
  settingPersonalWorkYear: {
    id: `${scope}.will.translate`,
    defaultMessage: 'def'
  },
  settingPersonalLocation: {
    id: `${scope}.will.translate`,
    defaultMessage: 'def'
  },
  settingPersonalDegree: {
    id: `${scope}.will.translate`,
    defaultMessage: 'def'
  },
  settingPersonalProfession: {
    id: `${scope}.will.translate`,
    defaultMessage: 'def'
  },
  settingPersonalEditProfile: {
    id: `${scope}.will.translate`,
    defaultMessage: 'def'
  },
  settingPersonalAboutMe: {
    id: `${scope}.will.translate`,
    defaultMessage: 'def'
  },
  settingScheduleTsagTohiruulah: {
    id: `${scope}.will.translate`,
    defaultMessage: 'def'
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Нэмэх'
  },
  appointmentCalendar: {
    id: `${scope}.will.translate`,
    defaultMessage: 'def'
  },
  chooseDay: {
    id: `${scope}.appointmentChooseDday`,
    defaultMessage: 'Та өдрөө сонгоно уу.'
  },
  cancel: {
    id: `${scope}.appointmentCancelAppointment`,
    defaultMessage: 'ЦУЦЛАХ'
  },
  transfer: {
    id: `${scope}.appointmentTransfer`,
    defaultMessage: 'ШИЛЖҮҮЛЭХ'
  },
  cancelAppointment: {
    id: `${scope}.cancelAppointment`,
    defaultMessage: 'Цаг захиалга цуцлах'
  },
  AppointmentDate: {
    id: `${scope}.AppointmentDate`,
    defaultMessage: ' Цуцлах захиалгын тов'
  },
  cancelAppointmentReason: {
    id: `${scope}.cancelAppointmentReason`,
    defaultMessage: 'Цуцлах болсон шалтгаан'
  },
  cancelAppointmentReasonPlaceholder: {
    id: `${scope}.cancelAppointmentReasonPlaceholder`,
    defaultMessage: 'Үйлчлүүлэгчид харагдах мэссэж'
  },
  cancelAppointmentSuggestADoctor: {
    id: `${scope}.cancelAppointmentSuggestADoctor`,
    defaultMessage: 'Эмч санал болгох эсэх'
  },
  cancelAppointmentSuggestADoctorPlaceholder: {
    id: `${scope}.will.translate`,
    defaultMessage: 'def'
  },
  cancelAppointmentQuestion: {
    id: `${scope}.cancelAppointmentQuestion`,
    defaultMessage: 'Та цаг захиалгыг цуцлахдаа итгэлтэй байна уу?'
  },
  transferModalTitle: {
    id: `${scope}.transferModalTitle`,
    defaultMessage: 'Шинэ цаг сонгох'
  },
  transferModalCalendarTitle: {
    id: `${scope}.transferModalCalendarTitle`,
    defaultMessage: ' Цаг захиалах боломжтой өдрүүд'
  },
  transferModalPreviousAppointment: {
    id: `${scope}.transferModalPreviousAppointment`,
    defaultMessage: 'Өмнөх тов '
  },
  transferModalReasonToTransfer: {
    id: `${scope}.transferModalReasonToTransfer`,
    defaultMessage: 'Шилжүүлэх болсон шалтгаан'
  },
  transferModalReasonPlaceholder: {
    id: `${scope}.will.translate`,
    defaultMessage: 'def'
  },
  transferModalAvailableItemChooseDay: {
    id: `${scope}.transferModalAvailableItemChooseDay`,
    defaultMessage: 'Та календариас боломжит өдрөө сонгоно уу'
  },
  transferModalAvailableItemChooseDayDescription: {
    id: `${scope}.customer.availableDays`,
    defaultMessage: 'өдрийн боломжит цагууд'
  },
  min: {
    id: `${scope}.min`,
    defaultMessage: 'мин'
  },
  hour: {
    id: `${scope}.hour`,
    defaultMessage: 'цаг'
  },
  sec: {
    id: `${scope}.sec`,
    defaultMessage: 'сек'
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Тийм'
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'Үгүй'
  },
  chatDoctorNoteNoDoctorRecord: {
    id: `${scope}.chat.home.sidebar.right.box3.box3`,
    defaultMessage: ' Эмчийн тэмдэглэл одоогоор үүсээгүй байна'
  },
  chatPersonalInfoSmokeYear: {
    id: `${scope}.chat.home.sidebar.right.box2.box14`,
    defaultMessage: 'Тийм'
  },
  currentPassword: {
    id: `${scope}.currentPassword`,
    defaultMessage: 'Одоогийн нууц үг'
  },
  newPassword: {
    id: `${scope}.newPassword`,
    defaultMessage: 'Шинэ нууц үг'
  },
  confirmNewPassword: {
    id: `${scope}.confirmNewPassword`,
    defaultMessage: 'Шинэ нууц үг баталгаажуулах'
  },
  settingExaminationCreateExamination: {
    id: `${scope}.settingExaminationCreateExamination`,
    defaultMessage: 'Үзлэгийн тохиргоо үүсгэх'
  },
  settingsexamnationCategory: {
    id: `${scope}.settings.examnationCategory`,
    defaultMessage: 'Үзлэгийн ангилал'
  },
  settingsexamnationType: {
    id: `${scope}.settings.examnationType`,
    defaultMessage: 'Үзлэгийн төрөл'
  },
  settingsexamnationPrice: {
    id: `${scope}.settings.examnationPrice`,
    defaultMessage: 'Төлбөр/MNT'
  },
  settingsexamnationDuration: {
    id: `${scope}.settings.examnationDuration`,
    defaultMessage: 'Үргэлжлэх хугацаа'
  },
  settingsexamnationDescription: {
    id: `${scope}.settings.examnationDescription`,
    defaultMessage: 'Тайлбар'
  },
  settingstimeSettings: {
    id: `${scope}.settings.timeSettings`,
    defaultMessage: 'Цаг тохируулах'
  },
  chooseCountry: {
    id: `${scope}.settings.chooseCountry`,
    defaultMessage: 'Улс сонгох'
  },
  chooseTimezone: {
    id: `${scope}.settings.chooseTimezone`,
    defaultMessage: 'Цагийн бүс сонгох'
  },
  settingsComment: {
    id: `${scope}.settings.comment`,
    defaultMessage: 'Сэтгэгдэл бичих'
  },
  settingsReply: {
    id: `${scope}.settings.reply`,
    defaultMessage: 'Хариулах'
  },
  areYouSureToDelete: {
    id: `${scope}.settings.areYouSureToDelete`,
    defaultMessage: 'дугаартай зөвлөгөөг устгах уу?'
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: 'Шинэ'
  },
  old: {
    id: `${scope}.old`,
    defaultMessage: 'Хуучин'
  },
  advice: {
    id: `${scope}.customer.medicalRecord.advice`,
    defaultMessage: 'Зөвлөгөө'
  },
  sariin: {
    id: `${scope}.sariin`,
    defaultMessage: 'сарын'
  },
  noAppointmentInfo: {
    id: `${scope}.noAppointmentInfo`,
    defaultMessage: 'Захиалгын мэдээлэл олдсонгүй'
  },
  closed: {
    id: `${scope}.closed`,
    defaultMessage: 'Хаагдсан'
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Идэвхтэй'
  },
  otherDoctors: {
    id: `${scope}.otherDoctors`,
    defaultMessage: 'Өөр эмч'
  },
  instruction: {
    id: `${scope}.customer.instruction`,
    defaultMessage: 'Заавар'
  },
  isEmergency: {
    id: `${scope}.customer.isEmergency`,
    defaultMessage: 'Яаралтай эсэх'
  },
  type: {
    id: `${scope}.customer.type`,
    defaultMessage: 'Хэлбэр'
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Имэйл'
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Нэр'
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Овог'
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: 'Гар утасны дугаар'
  },
  appointmentList: {
    id: `${scope}.appointmentList`,
    defaultMessage: 'Цаг захиалгын жагсаалт'
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Төлөв'
  },
  choose: {
    id: `${scope}.choose`,
    defaultMessage: 'Сонгох'
  },
  diagnosticPlan: {
    id: `${scope}.diagnosticPlan`,
    defaultMessage: 'Шинжилгээний төлөвлөгөө'
  },
  diagnosticName: {
    id: `${scope}.diagnosticName`,
    defaultMessage: 'Шинжилгээний нэр'
  },
  medicineName: {
    id: `${scope}.medicineName`,
    defaultMessage: 'Эм/Тариа -ны нэр'
  },
  dossage: {
    id: `${scope}.dossage`,
    defaultMessage: 'Тун/Хүч'
  },
  unit: {
    id: `${scope}.unit`,
    defaultMessage: 'Нэгж'
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Нийт тоо/хэмжээ'
  },
  usageInstruction: {
    id: `${scope}.usageInstruction`,
    defaultMessage: 'Хэрэглэх зааварчилгаа'
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Эхлэх огноо'
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'Дуусах огноо'
  },
  comments: {
    id: `${scope}.comments`,
    defaultMessage: 'сэтгэгдэл байна'
  },
  seeMore: {
    id: `${scope}.seeMore`,
    defaultMessage: 'Цааш үзэх'
  },
  open: {
    id: `${scope}.open`,
    defaultMessage: 'Дэлгэх'
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Хураах'
  },
  login: {
    id: `${scope}.login`,
    defaultMessage: 'Нэвтрэх'
  },
  recoverPassword: {
    id: `${scope}.recoverPassword`,
    defaultMessage: 'Нууц үг сэргээх'
  },
  register: {
    id: `${scope}.register`,
    defaultMessage: 'Бүртгүүлэх'
  },
  insertEmail: {
    id: `${scope}.insertEmail`,
    defaultMessage: 'И-мэйл хаягаа оруулна уу'
  },
  insertPassword: {
    id: `${scope}.insertPassword`,
    defaultMessage: 'Нууц үгээ оруулна уу'
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Нууц үг'
  },
  privacy: {
    id: `${scope}.privacy`,
    defaultMessage: 'Үйлчилгээний нөхцөлийг'
  },
  agreePrivacy: {
    id: `${scope}.agreePrivacy`,
    defaultMessage: 'хүлээн зөвшөөрч байгаа бол БҮРТГҮҮЛЭХ товч товч ээр дарж  бүргүүлнэ үү'
  },
  insertOnlyEmail: {
    id: `${scope}.insertOnlyEmail`,
    defaultMessage: 'Зөвхөн и-мэйл хаягаа оруулна уу'
  },
  insertName: {
    id: `${scope}.insertName`,
    defaultMessage: 'Нэр оруулна уу'
  },
  insertLastName: {
    id: `${scope}.insertLastName`,
    defaultMessage: 'Овог оруулна уу'
  },
  insert8DigitNumber: {
    id: `${scope}.insert8DigitNumber`,
    defaultMessage: '8 оронтой тооноос бүтсэн гар утасны дугаараа оруулна уу'
  },
  insertPhoneNumber: {
    id: `${scope}.insertPhoneNumber`,
    defaultMessage: 'Гар утасны дугаараа оруулна уу'
  },
  insert6digitPassword: {
    id: `${scope}.insert6digitPassword`,
    defaultMessage: '6-аас дээш оронтой нууц үг оруулна уу'
  },
  insertPasswordConfirm: {
    id: `${scope}.insertPasswordConfirm`,
    defaultMessage: 'Нууц үгээ давтан оруулна уу'
  },
  insertRegisteredEmail: {
    id: `${scope}.insertRegisteredEmail`,
    defaultMessage: 'Бүртгүүлсэн имэйл хаягаа оруулна уу'
  },
  getConfirmCode: {
    id: `${scope}.getConfirmCode`,
    defaultMessage: ' БАТАЛГААЖУУЛАХ КОД АВАХ'
  },
  wantToDeletePrescription: {
    id: `${scope}.wantToDeletePrescription`,
    defaultMessage: 'Эмийн жорыг устгах уу?'
  },
  wantToDeleteDiagnosticTest: {
    id: `${scope}.wantToDeleteDiagnosticTest`,
    defaultMessage: 'Өгөх шинжилгээний мэдээллийг устгах уу?'
  },
  addDiagnosticTest: {
    id: `${scope}.addDiagnosticTest`,
    defaultMessage: 'Шинжилгээ нэмэх'
  },
  editDiagnosticTest: {
    id: `${scope}.editDiagnosticTest`,
    defaultMessage: 'Шинжилгээ засах'
  },
  editprescription: {
    id: `${scope}.editprescription`,
    defaultMessage: 'Жор засах'
  },
  addprescription: {
    id: `${scope}.addprescription`,
    defaultMessage: 'Жор нэмэх'
  },
  rescheduleAppointment: {
    id: `${scope}.rescheduleAppointment`,
    defaultMessage: 'Цаг шилжүүлэх'
  },
  vacationSetting: {
    id: `${scope}.vacationSetting`,
    defaultMessage: 'Амралтын тохиргоо'
  },
  startTime: {
    id: `${scope}.startTime`,
    defaultMessage: 'Эхлэх цаг'
  },
  endTime: {
    id: `${scope}.endTime`,
    defaultMessage: 'Дуусах цаг'
  },
  file: {
    id: `${scope}.file`,
    defaultMessage: 'файл'
  },
  zoviurBichih: {
    id: `${scope}.zoviurBichih`,
    defaultMessage: 'Зовиур бичих'
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Хайх'
  },
  year: {
    id: `${scope}.year`,
    defaultMessage: 'Жил'
  },
  insertCurrentPassword: {
    id: `${scope}.insertCurrentPassword`,
    defaultMessage: 'Одоогийн нууц үгээ оруулна уу'
  },
  insertNewPassword: {
    id: `${scope}.insertNewPassword`,
    defaultMessage: 'Шинэ нууц үгээ оруулна уу'
  },
  repeat: {
    id: `${scope}.repeat`,
    defaultMessage: 'Давтамж'
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Тоо/хэмжээ'
  },
  medicineAmountExample: {
    id: `${scope}.medicineAmountExample`,
    defaultMessage: 'жишээ: 250 мг'
  },
  hoollolttoiHolbootoiEmchilgee: {
    id: `${scope}.hoollolttoiHolbootoiEmchilgee`,
    defaultMessage: 'Хооллолттой холбоотой эмчилгээ бичих'
  },
  zuvluguuBichih: {
    id: `${scope}.zuvluguuBichih`,
    defaultMessage: 'Зөвлөгөө бичих'
  },
  blogCreatePlaceholder: {
    id: `${scope}.blogCreatePlaceholder`,
    defaultMessage: 'НЭМЭХ товч дээр дарж гарчиг, жагсаалт  болон зураг сонгоорой.'
  },
  totalResult: {
    id: `${scope}.totalResult`,
    defaultMessage: 'Нийт олдоц'
  },
  serviceType: {
    id: `${scope}.settings.service.serviceType`,
    defaultMessage: 'Үйлчилгээний төрөл'
  },
  serviceDuration: {
    id: `${scope}.settings.service.duration`,
    defaultMessage: 'Үргэлжлэх хугацаа'
  },
  serviceAdditionalDescription: {
    id: `${scope}.settings.service.additionalDescription`,
    defaultMessage: 'Нэмэлт тайлбар'
  },
  serviceCreatedAt: {
    id: `${scope}.settings.service.createdAt`,
    defaultMessage: 'Үүсгэсэн огноо'
  },
  servicePrice: {
    id: `${scope}.settings.service.price`,
    defaultMessage: 'Үнэ'
  },
  serviceMinute: {
    id: `${scope}.settings.service.minute`,
    defaultMessage: 'Минут'
  },
  serviceDescriptions: {
    id: `${scope}.settings.service.descriptions`,
    defaultMessage: 'Тайлбар байна'
  },
  strengthUnitMg: {
    id: `${scope}.prescription.strengthUnit.MG`,
    defaultMessage: 'млг'
  },
  strengthUnitMcg: {
    id: `${scope}.prescription.strengthUnit.MCG`,
    defaultMessage: 'мкг'
  },
  strengthUnitGm: {
    id: `${scope}.prescription.strengthUnit.GM`,
    defaultMessage: 'грамм'
  },
  strengthUnitPercentage: {
    id: `${scope}.prescription.strengthUnit.PERCENTAGE`,
    defaultMessage: '%'
  },
  intakeMethodOral: {
    id: `${scope}.prescription.intakeMethod.ORAL`,
    defaultMessage: 'Амаар уух'
  },
  intakeMethodInjection: {
    id: `${scope}.prescription.intakeMethod.INJECTION`,
    defaultMessage: 'Тарих'
  },
  intakeMethodInEye: {
    id: `${scope}.prescription.intakeMethod.IN_EYE`,
    defaultMessage: 'Нүдэнд дусаах'
  },
  medicineFormTablet: {
    id: `${scope}.prescription.medicineForm.TABLET`,
    defaultMessage: 'Таблет'
  },
  medicineFormCapsule: {
    id: `${scope}.prescription.medicineForm.CAPSULE`,
    defaultMessage: 'Капсул'
  },
  medicineFormSyrup: {
    id: `${scope}.prescription.medicineForm.SYRUP`,
    defaultMessage: 'Сироп'
  },
  frequencyDaily: {
    id: `${scope}.prescription.frequency.DAILY`,
    defaultMessage: 'Өдөрт'
  },
  frequencyTwoDay: {
    id: `${scope}.prescription.frequency.TWO_DAY`,
    defaultMessage: 'Өнжөөд'
  },
  frequencyWeekly: {
    id: `${scope}.prescription.frequency.WEEKLY`,
    defaultMessage: '7 хоногт'
  },
  whenBeforeMeal: {
    id: `${scope}.prescription.when.BEFORE_MEAL`,
    defaultMessage: 'Хоолны өмнө'
  },
  whenAfterMeal: {
    id: `${scope}.prescription.when.AFTER_MEAL`,
    defaultMessage: 'Хоолны дараа'
  },
  whenBeforeSleep: {
    id: `${scope}.prescription.when.BEFORE_SLEEP`,
    defaultMessage: 'Унтахын өмнө'
  },
  prescriptionValidationName: {
    id: `${scope}.prescription.validation.name`,
    defaultMessage: 'Эм/Тариа -ны нэр оруулна уу'
  },
  prescriptionValidationMedicineForm: {
    id: `${scope}.prescription.validation.medicineForm`,
    defaultMessage: 'Эм/Тариа хэлбэрийг сонгоно уу'
  },
  prescriptionValidationStrength: {
    id: `${scope}.prescription.validation.strength`,
    defaultMessage: 'Эм/Тариа -ны тунг оруулна уу'
  },
  prescriptionValidationStrengthUnit: {
    id: `${scope}.prescription.validation.strengthUnit`,
    defaultMessage: 'Нэгжийг сонгоно  уу'
  },
  prescriptionValidationIntakeMethod: {
    id: `${scope}.prescription.validation.intakeMethod`,
    defaultMessage: 'Хэрэглэх аргыг сонгоно  уу'
  },
  prescriptionValidationTotalQuantity: {
    id: `${scope}.prescription.validation.totalQuantity`,
    defaultMessage: 'Нийт тоо/хэмжээг оруулна  уу'
  },
  prescriptionValidationFromDate: {
    id: `${scope}.prescription.validation.fromDate`,
    defaultMessage: 'Хэрэглэж эхлэх өдрийг сонгоно уу'
  },
  prescriptionValidationToDate: {
    id: `${scope}.prescription.validation.toDate`,
    defaultMessage: 'Хэрэглэж дуусах өдрийг сонгоно уу'
  },
  profileValidationBirthday: {
    id: `${scope}.profile.validation.birthday`,
    defaultMessage: ''
  },
  profileValidationBirthdayRequired: {
    id: `${scope}.profile.validation.birthdayRequired`,
    defaultMessage: ''
  },
  profileValidationGender: {
    id: `${scope}.profile.validation.gender`,
    defaultMessage: ''
  },
  profileValidationAbout: {
    id: `${scope}.profile.validation.about`,
    defaultMessage: ''
  },
  profileValidationLocation: {
    id: `${scope}.profile.validation.location`,
    defaultMessage: ''
  },
  profileValidationExperienceYear: {
    id: `${scope}.profile.validation.experienceYear`,
    defaultMessage: ''
  },
  profileValidationDegree: {
    id: `${scope}.profile.validation.degree`,
    defaultMessage: ''
  },
  timeTableWorkHour: {
    id: `${scope}.settings.timetable.header.workHour`,
    defaultMessage: 'Ажлын цаг'
  },
  timeTableBreaks: {
    id: `${scope}.settings.timetable.header.breaks`,
    defaultMessage: 'Завсарлагаа'
  },
  timeTableTimeOff: {
    id: `${scope}.settings.timetable.header.timeOff`,
    defaultMessage: 'Амралт'
  },
  timeTableInterval: {
    id: `${scope}.settings.timetable.header.interval`,
    defaultMessage: 'Интервал'
  },
  timeTableTimezone: {
    id: `${scope}.settings.timetable.header.timezone`,
    defaultMessage: 'Цагийн бүс'
  },
  doctorListTitle: {
    id: `${scope}.doctorList.title`,
    defaultMessage: ''
  },
  doctorListLastName: {
    id: `${scope}.doctorList.lastName`,
    defaultMessage: ''
  },
  doctorListFirstName: {
    id: `${scope}.doctorList.firstName`,
    defaultMessage: ''
  },
  doctorListMobile: {
    id: `${scope}.doctorList.mobile`,
    defaultMessage: ''
  },
  doctorListEmail: {
    id: `${scope}.doctorList.email`,
    defaultMessage: ''
  },
  doctorListIsFeatured: {
    id: `${scope}.doctorList.isFeatured`,
    defaultMessage: ''
  },
  doctorListDoctorType: {
    id: `${scope}.doctorList.doctorType`,
    defaultMessage: ''
  },
  doctorListNameAndMobile: {
    id: `${scope}.doctorList.nameAndMobile`,
    defaultMessage: ''
  },
  timeTableVacationWarning: {
    id: `${scope}.timeTable.vacation.warning`,
    defaultMessage: 'Нэгэнт захиалагдсан цаг захиалга нь амралтын хугацаанаас хамаарахгүйгээр хүчинтэй байхыг анхаарна уу'
  }
});
