import React from 'react';
import { useField } from 'formik';
import FormError from 'components/Common/FormError';
import Input from 'components/Common/Input';

const FormInput = ({ name, type, label, variant, margin, autoComplete, placeholder, rows, multiline, disabled }) => {
  const [field, meta] = useField(name);
  return (
    <>
      <Input
        field={field}
        variant={variant}
        name={field.name}
        margin={margin}
        type={type}
        label={label}
        autoComplete={autoComplete}
        placeholder={placeholder}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
      />
      {meta.touched && meta.error ? <FormError text={meta.error}></FormError> : null}
    </>
  );
};

export default FormInput;
