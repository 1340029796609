import React from 'react';

import ListItem from 'layout-components/SidebarCollapsed/ListItem';

const MenuIcon = props => {
  return (
    <ul className="sidebar-menu-collapsed">
      {props.menu &&
        props.menu.map((item, index) => (
          <li key={index}>
            <ListItem title={item.label} link={item.to} icon={item.icon} />
          </li>
        ))}
    </ul>
  );
};
export default MenuIcon;
