import React, { Component } from 'react';
import { connect } from 'react-redux';

const ExampleWrapperSeamless = props => {
    const { sidebarToggle } = props;
    return (
        <div style={{ marginLeft: sidebarToggle ? 90 : 10 }}>
            <div className="example-card-seamless mb-4-spacing">
                <h5 className="display-5 mb-4 font-weight-bold">{props.sectionHeading}</h5>
                <div>{props.children}</div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    sidebarToggle: state.ThemeOptions.sidebarToggle
});
export default connect(mapStateToProps)(ExampleWrapperSeamless);
