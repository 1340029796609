import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiAlert from '@material-ui/lab/Alert';

const AlertLabel = ({ text, description, color, icon }) => (
  <MuiAlert variant="outlined" className="alerts-alternate mb-4" color={color}>
    <div className="d-flex align-items-center align-content-start">
      <span className={`font-size-lg d-block d-40 mr-3 text-center text-white rounded-sm bg-${color}`}>
        <FontAwesomeIcon icon={icon} />
      </span>
      <span>
        <strong className="d-block">{text}</strong> {description}
      </span>
    </div>
  </MuiAlert>
);

export default AlertLabel;
