const pagePaths = {
    login: '/',
    recoverPassword: '/recover',
    register: '/register',
    verification: '/verification',
    customers: '/customers',
    customerDetalils: '/customer/details',
    rating: '/rating',
    account: '/account',
    profile: '/profile',
    settings: '/settings',
    calendar: '/calendar',
    liveCall: '/liveCall',
    chat: '/chat',
    dashboard: '/dashboard',
    videoRoom: '/videoRoom',
    post: '/post',
    postCreate: '/post/create',
    postEdit: '/post/edit',
    doctorList: '/doctor/list',
    serviceSettings: '/serviceSettings',
    page404: '/404',
    page500: '/500'
};

export { pagePaths };
