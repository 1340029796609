import React, { Fragment, useContext, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Avatar, Box, Badge, Menu, Button, List, ListItem, Divider, Switch } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import messages from '../../appMessages';
import avatar0 from '../../assets/images/avatars/avatar0.png';
import { withStyles } from '@material-ui/core/styles';
import { AuthContext } from 'context/AuthContext';
import { useHistory } from 'react-router-dom';
import BlankModal from 'components/Common/BlankModal';
import TestMicAndCamera from 'components/ApplicationsChat/VideoCall/TestMicAndCamera'

import { pagePaths } from 'constants/PagePaths';

const StyledBadge = withStyles({
    badge: {
        backgroundColor: 'var(--success)',
        color: 'var(--success)',
        boxShadow: '0 0 0 2px #fff',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""'
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0
        }
    }
})(Badge);
export default function HeaderUserbox() {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const userInfo = authContext.authState.userInfo;
    const presense = authContext.authState.presense;
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOnline, setIsOnline] = useState(presense === 'online');
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        //console.log('presense change: in userbox:', presense)

        setIsOnline(presense === 'online');
    }, [presense]);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const toggleChecked = () => {
        setIsOnline(prev => {
            authContext.setPresense(!prev ? 'online' : 'offline');
            return !prev;
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <Button
                color="inherit"
                onClick={handleClick}
                className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
                <Box>
                    {isOnline ? (
                        <StyledBadge
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            variant="dot">
                            <Avatar alt={userInfo.firstname} src={userInfo.avatar ? userInfo.avatar : avatar0} />
                        </StyledBadge>
                    ) : (
                        <Avatar alt={userInfo.firstname} src={userInfo.avatar ? userInfo.avatar : avatar0} />
                    )}
                </Box>
                <div className="d-none d-xl-block pl-3">
                    <div className="font-weight-bold pt-2 line-height-1">
                        {userInfo.firstname && userInfo.firstname} {userInfo.lastname && userInfo.lastname}
                    </div>
                </div>
                <span className="pl-1 pl-xl-3">
                    <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
                </span>
            </Button>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                onClose={handleClose}
                className="ml-2">
                <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0" style={{ minWidth: '18rem' }}>
                    <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
                        <Box>
                            {isOnline ? (
                                <StyledBadge
                                    overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    variant="dot">
                                    <Avatar
                                        alt={userInfo.firstname}
                                        src={userInfo.avatar ? userInfo.avatar : avatar0}
                                    />
                                </StyledBadge>
                            ) : (
                                <Avatar alt={userInfo.firstname} src={userInfo.avatar ? userInfo.avatar : avatar0} />
                            )}
                        </Box>
                        <div className="pl-3 ">
                            <div className="font-weight-bold text-center pt-2 line-height-1">
                                {userInfo.firstname && userInfo.firstname} {userInfo.lastname && userInfo.lastname}
                            </div>
                            <span className="text-black-50 text-center">{userInfo.email && userInfo.email}</span>
                        </div>
                        <Divider className="w-100 mt-2" />
                        <ListItem
                            button
                            onClick={() => {
                                history.push(pagePaths.profile);
                                setAnchorEl(null);
                            }}>
                            <FormattedMessage {...messages.headerextraboxrightrow1} />
                        </ListItem>
                        <ListItem
                            button
                            onClick={() => {
                                console.log('check device');
                                setShowModal(true)
                                handleClose()
                            }}>
                            <FormattedMessage {...messages.headerextraboxrightCheckDevice} />
                        </ListItem>
                        <ListItem button onClick={() => authContext.logout()}>
                            <FormattedMessage {...messages.headerextraboxrightrow2} />
                        </ListItem>
                        <Divider className="w-100" />
                        <ListItem className="p-0">
                            <div className="grid-menu grid-menu-2col w-100">
                                <div className="py-3">
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                {' '}
                                                <FormattedMessage {...messages.headerextraboxrightrow3} />:
                                            </div>
                                            <div className="pl-3 line-height-sm">
                                                {isOnline ? (
                                                    <FormattedMessage
                                                        {...messages.headerextraboxrightrow3statusonline}
                                                    />
                                                ) : (
                                                    <FormattedMessage
                                                        {...messages.headerextraboxrightrow3statusoffline}
                                                    />
                                                )}
                                            </div>
                                            <div className="pl-3 line-height-sm">
                                                <Switch
                                                    value="checkedA"
                                                    color="primary"
                                                    checked={isOnline}
                                                    onChange={toggleChecked}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ListItem>
                    </List>
                </div>
            </Menu>

            <BlankModal
                open={showModal}
                onClose={() => { setShowModal(false) }}
                maxWidth="md"
                title="Төхөөрөмж шалгах">
                <TestMicAndCamera />
            </BlankModal>
        </Fragment>
    );
}
