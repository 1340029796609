import React, { Fragment } from 'react';
import { Grid, Container, Card, CardContent } from '@material-ui/core';
import LoginBg from '../../../assets/images/register.png';
import { FormattedMessage } from 'react-intl';
import messages from '../../../appMessages';
import LoginForm from 'components/PagesLogin/LoginForm';
import SwitchLanguage from 'components/SwitchLanguage';


const LoginContent = () => {
    return (
        <Fragment>
            <div className="app-wrapper min-vh-100">
                <div className="app-main d-flex">
                    <div className="app-content p-0 flex-fill ">
                        <div className="d-flex flex-row-reverse">
                            <SwitchLanguage />
                        </div>
                        <div className="app-content--inner d-flex align-items-center ">
                            <div className="flex-grow-1 w-100 d-flex align-items-center justify-content-center">
                                <div className="bg-composed-wrapper--content py-5 ">
                                    <Container maxWidth="lg">
                                        <Grid container spacing={5} justify="center">
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                className="d-flex flex-column align-items-center ">
                                                <span className="w-100 text-center text-md-center pb-4">
                                                    <h1
                                                        className="display-3 mb-3 font-weight-bold"
                                                        style={{ color: '#079BAB', textAlign: 'center !important' }}>
                                                        <FormattedMessage {...messages.login} />
                                                    </h1>
                                                    {/* <p className="font-size-lg text-xl-left text-center mb-0 text-black-50">
                            Бүртгэлтэй хэрэглэгчээр нэвтэрч орно уу
                          </p> */}
                                                </span>
                                                <Card className="m-0 w-100 p-0 border-0">
                                                    <CardContent className="p-3">
                                                        <LoginForm />
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex-fill"
                        style={{
                            backgroundImage: `url(${LoginBg})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}>
                        {/* <img alt="..." className="" src={LoginBg} /> */}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export default LoginContent;
