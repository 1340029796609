import React, { lazy, Suspense, Fragment, useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';
import { PulseLoader } from 'react-spinners';
import MuiTheme from './theme';

// Layout Blueprints
import { LeftSidebar, MinimalLayout, PresentationLayout } from './layout-blueprints';

// Example Pages
import PagesLogin from './pages/PagesLogin';
import PagesRegister from './pages/PagesRegister';

import PagesRecoverPassword from './pages/PagesRecoverPassword';
import PagesError404 from './pages/PagesError404';
import PagesError500 from './pages/PagesError500';
import { AuthContext } from 'context/AuthContext';
import { pagePaths } from 'constants/PagePaths';
import { DoctorProvider } from 'context/DoctorContext';
import { CallProvider } from 'context/CallContext';
import { LanguageProvider } from 'context/LangContext';

const AuthenticatedRoute = ({ children, ...rest }) => {
    const authContext = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={() => (authContext.isAuthenticated() ? <>{children}</> : <Redirect to={pagePaths.login} />)}
        />
    );
};

const DashboardDefault = lazy(() => import('./pages/PagesDashboard'));
const PagesAccount = lazy(() => import('./pages/PagesAccount'));
const PagesChat = lazy(() => import('./pages/PagesChat'));
const PagesProfile = lazy(() => import('./pages/PagesProfile'));
const PagesCustomers = lazy(() => import('./pages/PagesCustomers'));
const PagesCustomerDetails = lazy(() => import('./pages/PagesCustomerDetails'));
const PagesRating = lazy(() => import('./pages/PagesRating'));
const PagesCalendar = lazy(() => import('./pages/PagesCalendar'));
const PagesSettings = lazy(() => import('./pages/PagesSettings'));
const PageLiveCall = lazy(() => import('./pages/PageLiveCall'));
const VideoRoom = lazy(() => import('./pages/VideoRoom'));
const PageTest = lazy(() => import('./pages/PageTest'));
const PagesPost = lazy(() => import('./pages/PagesPost'));
const PagesDoctorList = lazy(() => import('./pages/PagesDoctorList'));
const PagesServiceSettings = lazy(() => import('./pages/PagesServiceSettings'));

const Routes = () => {
    const location = useLocation();

    useEffect(() => {
        console.log('Routes did mount');
    }, []);

    const pageVariants = {
        initial: {
            opacity: 0,
            scale: 0.99
        },
        in: {
            opacity: 1,
            scale: 1
        },
        out: {
            opacity: 0,
            scale: 1.01
        }
    };

    const pageTransition = {
        type: 'tween',
        ease: 'anticipate',
        duration: 0.4
    };

    const SuspenseLoading = () => {
        return (
            <Fragment>
                <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                    <div className="d-flex align-items-center flex-column px-4">
                        <PulseLoader color={'#5383ff'} loading={true} />
                    </div>
                    <div className="text-muted font-size-xl text-center pt-3">
                        Түр хүлээнэ үү хуудас ачааллаж байна.
                    </div>
                </div>
            </Fragment>
        );
    };
    return (
        <ThemeProvider theme={MuiTheme}>
            <AnimatePresence>
                <Suspense fallback={<SuspenseLoading />}>
                    <Switch>
                        <LanguageProvider>
                            <Route exact path={[pagePaths.login]}>
                                <DoctorProvider>
                                    <PresentationLayout>
                                        <Switch location={location} key={location.pathname}>
                                            <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}>
                                                <Route path={pagePaths.login} component={PagesLogin} />
                                            </motion.div>
                                        </Switch>
                                    </PresentationLayout>
                                </DoctorProvider>
                            </Route>

                            <Route exact path={[pagePaths.register]}>
                                <DoctorProvider>
                                    <PresentationLayout>
                                        <Switch location={location} key={location.pathname}>
                                            <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}>
                                                <Route path={pagePaths.register} component={PagesRegister} />
                                            </motion.div>
                                        </Switch>
                                    </PresentationLayout>
                                </DoctorProvider>
                            </Route>

                            <Route path={[pagePaths.recoverPassword, pagePaths.page404, pagePaths.page500]}>
                                <DoctorProvider>
                                    <MinimalLayout>
                                        <Switch location={location} key={location.pathname}>
                                            <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}>
                                                <Route
                                                    path={pagePaths.recoverPassword}
                                                    component={PagesRecoverPassword}
                                                />
                                                <Route path={pagePaths.page404} component={PagesError404} />
                                                <Route path={pagePaths.page500} component={PagesError500} />
                                            </motion.div>
                                        </Switch>
                                    </MinimalLayout>
                                </DoctorProvider>
                            </Route>

                            <AuthenticatedRoute path={[`${pagePaths.chat}`, `${pagePaths.chat}/:id`]}>
                                <DoctorProvider>
                                    <CallProvider>
                                        {/* <FirebaseChatProvider>  */}
                                        {/* <CollapsedSidebar> */}

                                        <LeftSidebar>
                                            <Switch location={location} key={location.pathname}>
                                                <motion.div
                                                    initial="initial"
                                                    animate="in"
                                                    exit="out"
                                                    variants={pageVariants}
                                                    transition={pageTransition}>
                                                    <Route
                                                        path={[pagePaths.chat + '/:id', pagePaths.chat]}
                                                        component={PagesChat}
                                                    />
                                                </motion.div>
                                            </Switch>
                                        </LeftSidebar>

                                        {/* </CollapsedSidebar> */}
                                        {/* </FirebaseChatProvider> */}
                                    </CallProvider>
                                </DoctorProvider>
                            </AuthenticatedRoute>

                            <AuthenticatedRoute path={[`${pagePaths.videoRoom}`]}>
                                <DoctorProvider>
                                    <CallProvider>
                                        <Route path={pagePaths.videoRoom} component={VideoRoom} />
                                    </CallProvider>
                                </DoctorProvider>
                            </AuthenticatedRoute>

                            <AuthenticatedRoute
                                path={[
                                    `${pagePaths.dashboard}`,
                                    // `${pagePaths.chat}`,
                                    // `${pagePaths.chat}/:id`,
                                    `${pagePaths.profile}`,
                                    `${pagePaths.customers}`,
                                    `${pagePaths.customerDetalils}`,
                                    `${pagePaths.rating}`,
                                    `${pagePaths.calendar}`,
                                    `${pagePaths.settings}`,
                                    `${pagePaths.account}`,
                                    `${pagePaths.liveCall}`,
                                    `${pagePaths.post}`,
                                    `${pagePaths.doctorList}`,
                                    `${pagePaths.serviceSettings}`,
                                    '/test'
                                ]}>
                                <DoctorProvider>
                                    <CallProvider>
                                        <LeftSidebar>
                                            <Switch location={location} key={location.pathname}>
                                                <motion.div
                                                    initial="initial"
                                                    animate="in"
                                                    exit="out"
                                                    variants={pageVariants}
                                                    transition={pageTransition}>
                                                    <Route path={pagePaths.dashboard} component={DashboardDefault} />

                                                    {/* <Route
                                                    path={[pagePaths.chat + '/:id', pagePaths.chat]}
                                                    component={PagesChat}
                                                /> */}

                                                    <Route path={pagePaths.profile} component={PagesProfile} />
                                                    <Route
                                                        path={[
                                                            pagePaths.customers + '/:customerType',
                                                            pagePaths.customers
                                                        ]}
                                                        component={PagesCustomers}
                                                    />
                                                    <Route
                                                        path={pagePaths.customerDetalils + '/:id'}
                                                        component={PagesCustomerDetails}
                                                    />
                                                    <Route path={pagePaths.rating} component={PagesRating} />
                                                    <Route path={pagePaths.calendar} component={PagesCalendar} />
                                                    <Route path={pagePaths.settings} component={PagesSettings} />
                                                    <Route path={pagePaths.account} component={PagesAccount} />
                                                    <Route path={pagePaths.liveCall} component={PageLiveCall} />
                                                    <Route path={pagePaths.post} component={PagesPost} />
                                                    <Route path={pagePaths.doctorList} component={PagesDoctorList} />
                                                    <Route
                                                        path={pagePaths.serviceSettings}
                                                        component={PagesServiceSettings}
                                                    />
                                                    <Route path={'/test'} component={PageTest} />
                                                </motion.div>
                                            </Switch>
                                        </LeftSidebar>
                                    </CallProvider>
                                </DoctorProvider>
                            </AuthenticatedRoute>
                        </LanguageProvider>
                        <Route path="*" component={PagesError404} />
                    </Switch>
                </Suspense>
            </AnimatePresence>
        </ThemeProvider>
    );
};

export default Routes;
