import React, { Fragment, useContext, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from 'context/AuthContext';
import { makeStyles } from '@material-ui/core/styles';
import FormInput from 'components/Common/FormInput';

// import { publicFetch } from 'utils/PublicFetch';
import { pagePaths } from 'constants/PagePaths';
import { Button } from '@material-ui/core';

import AlertLabel from 'components/Common/AlertLabel';
// import jwtDecode from 'jwt-decode';
// import { apiPaths } from 'constants/ApiPaths';
import { FormattedMessage } from 'react-intl';
import messages from '../../../appMessages';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoginSchema = Yup.object().shape({
    username: Yup.string().required(<FormattedMessage {...messages.insertEmail} />),
    password: Yup.string().required(<FormattedMessage {...messages.insertPassword} />)
});

const useStyles = makeStyles(theme => ({
    myButton: {
        margin: theme.spacing(1),
        color: '#079BAB',
        borderColor: '#079BAB'
    }
}));

const LoginForm = () => {
    const history = useHistory();
    const { login } = useContext(AuthContext);
    const [loginSuccess, setLoginSuccess] = useState();
    const [loginError, setLoginError] = useState();
    const classes = useStyles();
    const [loginLoading, setLoginLoading] = useState(false);

    const submitForm = async formData => {
        try {
            setLoginLoading(true);
            setLoginSuccess(null);
            setLoginError(null);

            await login(formData);

            setLoginSuccess('Амжилттай нэвтэрлээ');
            setLoginError(null);

            history.push(pagePaths.dashboard);
        } catch (error) {
            console.log('login error:', error);
            setLoginLoading(false);
            const { data } = error.response;
            setLoginError(error && data ? data.message : 'Нэвтэрхэд алдаа гарлаа');
            setLoginSuccess(null);
        }
    };

    return (
        <Fragment>
            <Formik
                initialValues={{
                    username: '',
                    password: ''
                }}
                onSubmit={values => submitForm(values)}
                validationSchema={LoginSchema}>
                {() => (
                    <Form className="mt-8">
                        {loginSuccess && (
                            <AlertLabel text={loginSuccess} color="success" icon={['far', 'object-group']} />
                        )}
                        {loginError && (
                            <AlertLabel text={loginError} color="warning" icon={['far', 'question-circle']} />
                        )}

                        <div>
                            <div className="mb-3">
                                <FormInput
                                    variant="outlined"
                                    label={<FormattedMessage {...messages.email} />}
                                    name="username"
                                    type="text"
                                    placeholder="И-мэйл"
                                />
                            </div>
                            <div className="mb-3">
                                <FormInput
                                    variant="outlined"
                                    label={<FormattedMessage {...messages.password} />}
                                    name="password"
                                    type="password"
                                    placeholder="Нууц үг"
                                />
                            </div>
                            <div className="mb-3">
                                <Link to={'/recover'}>
                                    {' '}
                                    <FormattedMessage {...messages.recoverPassword} />
                                </Link>{' '}
                                &nbsp;&nbsp;
                                <Link to={'/register'}>
                                    {' '}
                                    <FormattedMessage {...messages.register} />
                                </Link>
                            </div>
                        </div>

                        <div className="text-center">
                            <Button
                                type="submit"
                                variant="outlined"
                                size="large"
                                className={classes.myButton}
                                style={{ position: 'relative', paddingLeft: '30px', paddingRight: '30px' }}>
                                {loginLoading && (
                                    <CircularProgress size={20} style={{ left: '5px', position: 'absolute', color: '#079BAB' }} />
                                )}
                                <FormattedMessage {...messages.login} />
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
};
export default LoginForm;
