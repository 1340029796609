import React, { Fragment, useEffect, useState } from 'react';
import { publicFetch } from 'utils/PublicFetch';
import { apiPaths } from 'constants/ApiPaths';
import ReactHtmlParser from 'react-html-parser';

const TermForm = props => {
    const [loading, setLoading] = useState(false)
    const [content, setContent] = useState('')
    const [message, setMessage] = useState('')

    useEffect(() => {
        fetchTerm()
    }, [])

    const fetchTerm = async () => {
        try {
            setLoading(true);
            const { data } = await publicFetch.get(apiPaths.getTerm);


            setContent(data.result.pageContent.content);
            setLoading(false);
        } catch (error) {
            setMessage(error.message);
            setLoading(false);
        }
    };

    return (
        <div>
            {content ? ReactHtmlParser(content) : ''}
        </div>
    )
}

export default TermForm