import React, { createContext, useState } from 'react';

const DoctorContext = createContext();
const { Provider } = DoctorContext;

const DoctorProvider = ({ children }) => {
  const doctorProfile = localStorage.getItem('doctorProfile');

  const [doctorState, setDoctorState] = useState({
    doctorProfile: doctorProfile ? JSON.parse(doctorProfile) : {},
    selectedChatCustomer: null
  });

  const setSelectedChatCustomer = ({ customer }) => {
    console.log('setSelectedChatCustomer')
    
    setDoctorState(prevState => {
      return { ...prevState, selectedChatCustomer: customer };
    });
  };

  const setDoctorProfile = ({ doctorProfile }) => {
    console.log('setDoctorProfile')

    localStorage.setItem('doctorProfile', JSON.stringify(doctorProfile));
    setDoctorState(prevState => {
      return { ...prevState, doctorProfile: doctorProfile };
    });
  };

  return (
    <Provider
      value={{
        doctorState,
        setDoctorProfile: profile => setDoctorProfile(profile),
        setSelectedChatCustomer
      }}>
      {children}
    </Provider>
  );
};

export { DoctorContext, DoctorProvider };
