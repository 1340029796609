import React, { useState, useRef, useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { Select, MenuItem, FormControl, InputLabel, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const TestMicAndCamera = props => {
    const container = useRef(null);
    const [localAudioTrack, setLocalAudioTrack] = useState();
    const [localVideoTrack, setLocalVideoTrack] = useState();
    const [micList, setMicList] = useState([]);
    const [cameraList, setCameraList] = useState([]);
    const [speakerList, setSpeakerList] = useState([]);
    const [selectedMic, setSelectedMic] = useState({ deviceId: '' });
    const [selectedSpeaker, setSelectedSpeaker] = useState({ deviceId: '' });
    const [selectedCamera, setSelectedCamera] = useState({ deviceId: '' });
    let [loading, _setLoading] = useState({ mic: false, camera: false, speaker: false });
    const [messageList, _setMessageList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    let [initialized, _setInitialized] = useState({ mic: false, speaker: false, camera: false });

    const setLoading = params => {
        loading = params;
        _setLoading(params);
    };

    const setInitialized = value => {
        initialized = value;
        _setInitialized(value);
    };

    const setMessageList = item => {
        messageList.push(item);
        _setMessageList([...messageList]);
    };

    const checkLoading = () => {
        const { mic, speaker, camera } = loading;
        return mic || speaker || camera;
    };

    const initMic = async () => {
        setLoading({ ...loading, mic: true });
        if (!props?.localAudioTrack) {
            const audioTrack = await AgoraRTC.createMicrophoneAudioTrack().catch(error => {
                if (error?.code === 'DEVICE_NOT_FOUND') {
                    setMessageList('* Микрофон олдсонгүй');
                } else if (error?.code === 'PERMISSION_DENIED') {
                    setMessageList('* Микрофон хандах эрхгүй байна');
                } else {
                    setMessageList('* Микрофон шалгахад алдаа гарлаа');
                }
            });
            setLocalAudioTrack(audioTrack);
        } else {
            setLocalAudioTrack(props.localAudioTrack);
        }
        const micList = await AgoraRTC.getMicrophones();
        setMicList(micList);
        let defaultMic = micList.find(item => item?.deviceId === 'default');
        if (!defaultMic) {
            if (micList.length > 0) {
                defaultMic = micList[0];
            }
        }
        setSelectedMic(defaultMic);
        setLoading({ ...loading, mic: false });
        setInitialized({ ...initialized, mic: true });
    };

    const initCamera = async () => {
        setLoading({ ...loading, camera: true });
        if (!props?.localVideoTrack) {
            const videoTrack = await AgoraRTC.createCameraVideoTrack().catch(error => {
                if (error?.code === 'DEVICE_NOT_FOUND') {
                    setMessageList('* Камер олдсонгүй');
                } else if (error?.code === 'PERMISSION_DENIED') {
                    setMessageList('* Камер хандах эрхгүй байна');
                } else {
                    setMessageList('* Камер шалгахад алдаа гарлаа');
                }
            });
            setLocalVideoTrack(videoTrack);
        } else {
            setLocalVideoTrack(props.localVideoTrack);
        }
        const cameraList = (await AgoraRTC.getCameras()) || [];
        setCameraList(cameraList);
        console.log('cameraList: ', cameraList);
        let defaultCamera = cameraList.find(item => item?.deviceId === 'default');
        if (!defaultCamera) {
            if (cameraList.length > 0) {
                defaultCamera = cameraList[0];
            }
        }
        setSelectedCamera(defaultCamera);
        setLoading({ ...loading, camera: false });
        setInitialized({ ...initialized, camera: true });
    };

    const initSpeaker = async () => {
        setLoading({ ...loading, speaker: true });
        const speakerList = (await AgoraRTC.getPlaybackDevices()) || [];
        setSpeakerList(speakerList);
        console.log('speakerList: ', speakerList);

        let defaultSpeaker = speakerList.find(item => item?.deviceId === 'default');
        if (!defaultSpeaker) {
            if (speakerList.length > 0) {
                defaultSpeaker = speakerList[0];
            }
        }
        if (speakerList?.length < 1) {
            setMessageList([...messageList, 'Спикер олдсонгүй']);
        }
        setSelectedSpeaker(defaultSpeaker);
        setLoading({ ...loading, speaker: false });
    };

    useEffect(() => {
        initMic();
        initCamera();
        initSpeaker();
    }, []);

    useEffect(() => {
        if (props.localAudioTrack) {
            setLocalAudioTrack(props.localAudioTrack);
        }
    }, [props.localAudioTrack]);

    useEffect(() => {
        if (props.localVideoTrack) {
            setLocalVideoTrack(props.localVideoTrack);
        }
    }, [props.localVideoTrack]);

    useEffect(() => {
        if (!initialized.mic) {
            return;
        }
        if (selectedMic?.deviceId) {
            setLoading({ ...loading, mic: true });
            localAudioTrack
                .setDevice(selectedMic.deviceId)
                .then(() => {
                    enqueueSnackbar('Микрофон амжилттай солигдлоо', { variant: 'success' });
                })
                .catch(() => {
                    enqueueSnackbar('Микрофон солиход алдаа гарлаа', { variant: 'error' });
                })
                .finally(() => {
                    setLoading({ ...loading, mic: false });
                });
        }
    }, [selectedMic]);

    useEffect(() => {
        if (!initialized.camera) {
            return;
        }
        if (selectedCamera?.deviceId) {
            setLoading({ ...loading, camera: true });
            localVideoTrack
                .setDevice(selectedCamera.deviceId)
                .then(() => {
                    enqueueSnackbar('Камер амжилттай солигдлоо', { variant: 'success' });
                })
                .catch(() => {
                    enqueueSnackbar('Камер солиход алдаа гарлаа', { variant: 'error' });
                })
                .finally(() => {
                    setLoading({ ...loading, camera: false });
                });
        }
    }, [selectedCamera]);

    useEffect(() => {
        if (!container.current) return;
        if (localVideoTrack) {
            localVideoTrack.play(container.current);
        }
        return () => {
            if (props.localVideoTrack && props.localVideoTrack === localVideoTrack) {
                return;
            }
            if (localVideoTrack) {
                localVideoTrack.stop();
                localVideoTrack.close();
            }
        };
    }, [container, localVideoTrack]);

    useEffect(() => {
        if (localAudioTrack) {
            localAudioTrack.play();
        }
        return () => {
            if (props.localAudioTrack && props.localAudioTrack === localAudioTrack) {
                return;
            }

            if (localAudioTrack) {
                localAudioTrack.stop();
                localAudioTrack.close();
            }
        };
    }, [localAudioTrack]);

    const onChangeMic = event => {
        const deviceId = event.target.value;
        const mic = micList.find(item => item.deviceId === deviceId);
        if (mic) {
            setSelectedMic(mic);
        }
    };

    const onChangeCamera = event => {
        const deviceId = event.target.value;
        const camera = cameraList.find(item => item.deviceId === deviceId);
        if (camera) {
            setSelectedCamera(camera);
        }
    };

    return (
        <div style={{ paddingBottom: '20px', display: 'flex' }}>
            <div
                style={{
                    minWidth: '300px',
                    maxWidth: '300px',
                    paddingRight: '30px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <div>
                    <FormControl style={{ width: '100%', paddingBottom: '20px' }}>
                        <InputLabel id="demo-simple-select-required-label">Микрофон</InputLabel>
                        <Select style={{ width: '100%' }} value={selectedMic?.deviceId || ''} onChange={onChangeMic}>
                            {micList.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item?.deviceId}>
                                        {item?.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: '100%', paddingBottom: '20px' }}>
                        <InputLabel id="demo-simple-select-required-label">Спикер</InputLabel>
                        <Select style={{ width: '100%' }} value={selectedSpeaker?.deviceId || ''}>
                            {speakerList.map((item, index) => {
                                return (
                                    <MenuItem key={index} disabled value={item?.deviceId}>
                                        {item?.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: '100%', paddingBottom: '20px' }}>
                        <InputLabel id="demo-simple-select-required-label">Камер</InputLabel>
                        <Select
                            style={{ width: '100%' }}
                            value={selectedCamera?.deviceId || ''}
                            onChange={onChangeCamera}>
                            {cameraList.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item?.deviceId}>
                                        {item?.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: 'flex', flex: '1', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    {messageList.map((message, index) => {
                        return (
                            <div key={index} style={{ padding: '4px 0px 4px 0px', color: '#EF5350' }}>
                                {message}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div
                ref={container}
                style={{
                    marginTop: '20px',
                    width: '600px',
                    height: '480px',
                    backgroundColor: '#A7ACAF',
                    position: 'relative'
                }}>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'transparent'
                    }}>
                    {checkLoading() && <CircularProgress style={{ zIndex: 700 }} />}
                </div>
            </div>
        </div>
    );
};

export default TestMicAndCamera;
