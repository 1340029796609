import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import FormInput from 'components/Common/FormInput';
import { publicFetch } from 'utils/PublicFetch';
import { apiPaths } from 'constants/ApiPaths';
import AlertLabel from 'components/Common/AlertLabel';
import BlankModal from 'components/Common/BlankModal';
import TermForm from 'components/TermForm';
import { FormattedMessage } from 'react-intl';
import messages from '../../../appMessages';
import CircularProgress from '@material-ui/core/CircularProgress';

const RegisterSchema = Yup.object().shape({
    email: Yup.string()
        .email(<FormattedMessage {...messages.insertOnlyEmail} />)
        .required(<FormattedMessage {...messages.insertEmail} />),
    firstname: Yup.string().required(<FormattedMessage {...messages.insertName} />),
    lastname: Yup.string().required(<FormattedMessage {...messages.insertLastName} />),
    mobile: Yup.number()
        .typeError(<FormattedMessage {...messages.insert8DigitNumber} />)
        .required(<FormattedMessage {...messages.insertPhoneNumber} />),
    password: Yup.string()
        .min(6, <FormattedMessage {...messages.insert6digitPassword} />)
        .required(<FormattedMessage {...messages.insertPassword} />),
    passwordConfirm: Yup.string().when('password', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], <FormattedMessage {...messages.insertPasswordConfirm} />)
    })
});
const useStyles = makeStyles(theme => ({
    myButton: {
        margin: theme.spacing(1),
        color: '#079BAB',
        borderColor: '#079BAB'
    }
}));

const RegisterForm = () => {
    const history = useHistory();
    const [registerSuccess, setRegisterSuccess] = useState();
    const [registerError, setRegisterError] = useState();
    const [registerLoading, setRegisterLoading] = useState(false);
    const classes = useStyles();
    const [showTermDialog, setShowTermDialog] = useState(false);
    const showForm = () => {
        setShowTermDialog(true);
    };
    const closeForm = () => {
        setShowTermDialog(false);
    };

    const submitCredentials = async (credentials, { resetForm }) => {
        try {
            setRegisterLoading(true);
            setRegisterSuccess(null);
            setRegisterError(null);

            await publicFetch.post(apiPaths.register, credentials);

            setRegisterSuccess(
                `Хэрэглэгч амжилттай бүртггэдлээ. Бүртгэгдсэн хэрэглэгчээр нэвтэрч орно уу (${credentials.email})`
            );
            setRegisterError(null);
            setRegisterLoading(false);
            resetForm({});
        } catch (error) {
            console.log('register error:', error);
            setRegisterLoading(false);
            const { data } = error.response;
            setRegisterError(error && data ? data.message : 'Error');
            setRegisterSuccess(null);
        }
    };

    return (
        <Fragment>
            <Formik
                initialValues={{
                    email: '',
                    firstname: '',
                    lastname: '',
                    mobile: '',
                    password: '',
                    passwordConfirm: ''
                }}
                onSubmit={(values, { resetForm }) => submitCredentials(values, { resetForm })}
                validationSchema={RegisterSchema}>
                {() => (
                    <Form className="mt-8">
                        {registerError && <AlertLabel text={registerError} color="warning" icon="question-circle" />}
                        {registerSuccess ? (
                            <>
                                <AlertLabel text={registerSuccess} color="success" icon="object-group" />
                                <div className="text-center">
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        size="large"
                                        className={classes.myButton}
                                        onClick={() => {
                                            history.push('/');
                                        }}>
                                        Нэвтрэх хуудас
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <div className="mb-3">
                                        <FormInput
                                            variant="outlined"
                                            label={<FormattedMessage {...messages.email} />}
                                            name="email"
                                            type="text"
                                            // placeholder="И-мэйл"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <FormInput
                                            variant="outlined"
                                            label={<FormattedMessage {...messages.lastName} />}
                                            name="lastname"
                                            // placeholder="Овог"
                                            type="text"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <FormInput
                                            variant="outlined"
                                            label={<FormattedMessage {...messages.name} />}
                                            name="firstname"
                                            // placeholder="Нэр"
                                            type="text"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <FormInput
                                            variant="outlined"
                                            label={<FormattedMessage {...messages.phoneNumber} />}
                                            name="mobile"
                                            // placeholder="Гар утасны дугаар"
                                            type="text"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <FormInput
                                            variant="outlined"
                                            label={<FormattedMessage {...messages.password} />}
                                            name="password"
                                            type="password"
                                            // placeholder="Нууц үг"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <FormInput
                                            variant="outlined"
                                            label={<FormattedMessage {...messages.confirmNewPassword} />}
                                            name="passwordConfirm"
                                            type="password"
                                            // placeholder="Нууц үг баталгаажуулах"
                                        />
                                    </div>
                                    <div className="form-group pt-2 mb-4 text-center">
                                        <Button onClick={showForm} style={{ color: '#079BAB' }}>
                                            <FormattedMessage {...messages.privacy} />
                                        </Button>{' '}
                                        <FormattedMessage {...messages.agreePrivacy} />
                                    </div>
                                </div>

                                <div className="text-center">
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        size="large"
                                        className={classes.myButton}
                                        style={{ position: 'relative', paddingLeft: '30px', paddingRight: '30px' }}>
                                        {registerLoading && (
                                            <CircularProgress
                                                size={20}
                                                style={{ left: '5px', position: 'absolute', color: '#079BAB' }}
                                            />
                                        )}
                                        <FormattedMessage {...messages.register} />
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form>
                )}
            </Formik>

            <BlankModal open={showTermDialog} onClose={closeForm} maxWidth="md" title="Emch.app Үйлчилгээний Нөхцөл">
                <TermForm onClose={closeForm} />
            </BlankModal>
        </Fragment>
    );
};
export default RegisterForm;
